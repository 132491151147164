import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import {
  Box,
  Button,
  Textarea,
  Input,
  Select,
  Checkbox,
  VStack,
  HStack,
  Heading,
  useToast
} from '@chakra-ui/react';

const EmailForm = () => {
    const [users, setUsers] = useState([]);
    const [reservations, setReservations] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedUsers, setSelectedUsers] = useState({});
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [sendingStatus, setSendingStatus] = useState('idle');
    const toast = useToast();

    useEffect(() => {
        const fetchReservations = async () => {
            const resSnapshot = await getDocs(collection(db, "reservations"));
            const dates = resSnapshot.docs.map(doc => doc.data().date);
            const uniqueDates = Array.from(new Set(dates)).sort();
            setReservations(uniqueDates);
        };

        fetchReservations();
    }, []);

    useEffect(() => {
        const fetchUsersByDate = async () => {
            if (!selectedDate) {
                setUsers([]);
                return;
            }
            const resQuery = query(collection(db, "reservations"), where("date", "==", selectedDate));
            const querySnapshot = await getDocs(resQuery);
            const userIds = querySnapshot.docs.map(doc => doc.data().userId);

            const usersData = [];
            for (const userId of userIds) {
                const userDocRef = doc(db, "users", userId);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const userDoc = userDocSnap.data();
                    usersData.push({
                        id: userId,
                        email: userDoc.email,
                        firstname: userDoc.firstName,
                        lastName: userDoc.lastName,
                        checked: false
                    });
                }
            }
            setUsers(usersData);
        };

        fetchUsersByDate();
    }, [selectedDate]);

    const sendEmail = async () => {
        setSendingStatus('processing');
        const functions = getFunctions(db);
        const sendEmailFunction = httpsCallable(functions, 'sendEmail');

        for (const user of users.filter(user => selectedUsers[user.id])) {
            try {
                await sendEmailFunction({
                    to: user.email,
                    subject: subject,
                    text: message
                });
                console.log('Email sent successfully to:', user.email);
            } catch (error) {
                console.error('Failed to send email to', user.email, ':', error);
                toast({
                    title: "Error sending email",
                    description: `Failed to send email to ${user.email}`,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
                setSendingStatus('error');
                return;
            }
        }

        toast({
            title: "Emails sent",
            description: "All emails have been sent successfully!",
            status: "success",
            duration: 9000,
            isClosable: true,
        });
        setSendingStatus('completed');
    };

    const handleCheck = (id) => {
        setSelectedUsers(prev => ({ ...prev, [id]: !prev[id] }));
    };

    const handleCheckAll = () => {
        const newSelectedUsers = {};
        users.forEach(user => {
            newSelectedUsers[user.id] = true;
        });
        setSelectedUsers(newSelectedUsers);
    };

    return (
        <Box p={5}>
            <Heading mb={4}>Send Email</Heading>
            <Select placeholder="Select date" onChange={(e) => setSelectedDate(e.target.value)}>
                <option value="">Select Date</option>
                {reservations.map((date, index) => (
                    <option key={index} value={date}>{date}</option>
                ))}
            </Select>
            <Input
                mt={4}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Subject"
                required
            />
            <Textarea
                mt={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Message"
                required
            />
            <Button mt={4} onClick={handleCheckAll}>Check All</Button>
            <VStack mt={4} align="stretch">
                <Heading size="md">Users</Heading>
                {users.map(user => (
                    <HStack key={user.id}>
                        <Checkbox isChecked={!!selectedUsers[user.id]} onChange={() => handleCheck(user.id)} />
                        {user.firstname} {user.lastName} ({user.email})
                    </HStack>
                ))}
            </VStack>
            <Button mt={4} onClick={sendEmail} isLoading={sendingStatus === 'processing'}>
                {sendingStatus === 'processing' ? 'Sending...' : 'Send Email'}
            </Button>
        </Box>
    );
};

export default EmailForm;
