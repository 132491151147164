import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, Text, Button, VStack, Flex, Spacer, IconButton, Textarea, useMediaQuery, useColorModeValue, Avatar, AvatarGroup, Grid, GridItem, Badge, Collapse, Tag, TagLabel, Input } from '@chakra-ui/react';
import { getAuth } from 'firebase/auth';
import { collection, query, where, doc, getDoc, getDocs, addDoc, deleteDoc, updateDoc, serverTimestamp, orderBy, limit } from 'firebase/firestore';
import { db } from '../firebase-config';
import { ArrowBackIcon, DeleteIcon } from '@chakra-ui/icons';

function Team() {
  const [messages, setMessages] = useState([]);
  const [newMessageTopic, setNewMessageTopic] = useState('');
  const [newMessageBody, setNewMessageBody] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [gymId, setGymId] = useState('');
  const [hasGym, setHasGym] = useState(false);
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [activeMessageId, setActiveMessageId] = useState(null);
  const [gymData, setGymData] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);

  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const bgColor = useColorModeValue('gray.100', 'gray.800');
  const boxBgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const [gymPoints, setGymPoints] = useState(0);
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
    const fetchGymPoints = async () => {
      if (gymId) {
        const teamQuery = query(collection(db, 'users'), where('gym', '==', gymId));
        const teamSnapshot = await getDocs(teamQuery);
        const teamMembers = teamSnapshot.docs.map(doc => doc.data());
        
        const totalScore = teamMembers.reduce((sum, member) => sum + member.score, 0);
        const averageScore = teamMembers.length > 0 ? totalScore / teamMembers.length : 0;
        
        setGymPoints(averageScore);
      }
    };
  
    fetchGymPoints();
  }, [gymId]);

  useEffect(() => {
    const checkGymAssignment = async () => {
      if (user) {
        setIsLoading(true);
        const userDocRef = doc(db, 'users', user.uid);
        try {
          const userDocSnapshot = await getDoc(userDocRef);
          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            setUserFirstName(userData.firstName);
            setUserLastName(userData.lastName);
            
            if (userData.gym && userData.gymRequest && userData.gymRequest.status === 'approved') {
              const gymQuery = query(collection(db, 'gyms'), where('name', '==', userData.gym));
              const gymSnapshot = await getDocs(gymQuery);
              if (!gymSnapshot.empty) {
                const fetchedGymData = gymSnapshot.docs[0].data();
                setGymData(fetchedGymData);
                setGymId(gymSnapshot.docs[0].id);
                setHasGym(true);
  
                // Fetch upcoming events
                fetchUpcomingEvents();
              } else {
                setHasGym(false);
                setGymData(null);
              }
            } else {
              setHasGym(false);
            }
          } else {
            console.error('No user document found with UID:', user.uid);
            setHasGym(false);
          }
        } catch (error) {
          console.error('Error fetching gym assignment:', error);
          setHasGym(false);
        } finally {
          setIsLoading(false);
        }
      } else {
        console.error('No user logged in.');
        setHasGym(false);
        setIsLoading(false);
      }
    };
  
    checkGymAssignment();
  }, [user]);
  
  

  const fetchUpcomingEvents = async () => {
    if (gymId) {
      const gymRef = doc(db, 'gyms', gymId);
      const gymSnapshot = await getDoc(gymRef);
      if (gymSnapshot.exists()) {
        const gymData = gymSnapshot.data();
        const events = gymData.upcomingEvents || [];
        setUpcomingEvents(events); // Update the state with the fetched events
      }
    }
  };
  
  useEffect(() => {
    if (gymId) {
      fetchMessages();
    }
  }, [gymId]);
  useEffect(() => {
    if (gymId) {
      fetchUpcomingEvents();
    }
  }, [gymId]);
  const handleUpdateGymPoints = async (newPoints) => {
    if (user.uid === gymData?.captain) {
      setGymPoints(newPoints);
      await updateDoc(doc(db, 'gyms', gymId), { points: newPoints });
    }
  };
  
  const handleAddEvent = async () => {
    if (user.uid === gymData?.captain) {
      const newEventTitle = prompt('Enter the event title:');
      const newEventDate = prompt('Enter the event date:');
  
      if (newEventTitle && newEventDate) {
        const eventId = Date.now();
        const newEvent = { id: eventId, title: newEventTitle, date: newEventDate };
        const updatedEvents = [...upcomingEvents, newEvent];
        await updateDoc(doc(db, 'gyms', gymId), { upcomingEvents: updatedEvents });
        fetchUpcomingEvents(); // Fetch the updated events from Firestore
      }
    }
  };
  
  const handleUpdateEvent = async (updatedEvent) => {
    if (user.uid === gymData?.captain) {
      const updatedEvents = upcomingEvents.map((event) =>
        event.id === updatedEvent.id ? updatedEvent : event
      );
      setUpcomingEvents(updatedEvents);
      await updateDoc(doc(db, 'gyms', gymId), { upcomingEvents: updatedEvents });
    }
  };
  
  const handleDeleteEvent = async (eventId) => {
    if (user.uid === gymData?.captain) {
      const updatedEvents = upcomingEvents.filter((event) => event.id !== eventId);
      setUpcomingEvents(updatedEvents);
      await updateDoc(doc(db, 'gyms', gymId), { upcomingEvents: updatedEvents });
    }
  };

  const fetchMessages = async () => {
    if (gymId) {
      const messagesRef = collection(db, 'gyms', gymId, 'messages');
      const q = query(messagesRef, orderBy('timestamp', 'desc'), limit(10));
      const querySnapshot = await getDocs(q);
      const messagesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        replies: doc.data().replies || []
      }));
  
      // Sort messages based on the latest reply timestamp or the message timestamp
      const sortedMessages = messagesData.sort((a, b) => {
        const latestReplyTimestampA = a.replies.length > 0 ? a.replies[a.replies.length - 1].timestamp : a.timestamp;
        const latestReplyTimestampB = b.replies.length > 0 ? b.replies[b.replies.length - 1].timestamp : b.timestamp;
        return latestReplyTimestampB - latestReplyTimestampA;
      });
  
      setMessages(sortedMessages);
    }
  };

  const toggleReplies = (messageId) => {
    setActiveMessageId(activeMessageId === messageId ? null : messageId);
  };

  const handleDeleteMessage = async (messageId, messageSenderId) => {
    if (!user) {
      alert("You must be logged in to perform this action.");
      return;
    }
  
    const gymRef = doc(db, 'gyms', gymId);
    const gymSnap = await getDoc(gymRef);
  
    if (!gymSnap.exists()) {
      console.error("Gym not found.");
      return;
    }
  
    const gymData = gymSnap.data();
    const captainId = gymData.captain;
  
    if (user.uid === captainId) {
      const confirmDelete = window.confirm("You are the captain. Do you want to delete this message and replace it with a note?");
      if (confirmDelete) {
        const messageRef = doc(db, 'gyms', gymId, 'messages', messageId);
        // Check if the message has replies, if not, delete it completely
        const messageSnap = await getDoc(messageRef);
        if (messageSnap.exists() && (messageSnap.data().replies || []).length === 0) {
          // If there are no replies, delete the message completely
          await deleteDoc(messageRef);
        } else {
          // Otherwise, replace the message text and clear replies if it has replies
          await updateDoc(messageRef, {
            text: `This message was removed by the captain.`,
            senderId: null,
            replies: []
          });
        }
        console.log("Message handled by captain.");
      }
    } else if (user.uid === messageSenderId) {
      const confirmDeleteOwn = window.confirm("Are you sure you want to delete your own message?");
      if (confirmDeleteOwn) {
        const messageRef = doc(db, 'gyms', gymId, 'messages', messageId);
        // Delete the message completely
        await deleteDoc(messageRef);
        console.log("Message deleted by the owner.");
      }
    } else {
      console.error("You do not have permission to delete this message.");
      alert("You can only delete your own messages or manage as the captain.");
    }
  
    // Refresh the messages list to show changes
    fetchMessages();
  };
  
  const handleReplyTextChange = (e, messageId) => {
    const replyText = e.target.value;
    setMessages(prevMessages => prevMessages.map(msg => {
      if (msg.id === messageId) {
        return { ...msg, replyText };
      }
      return msg;
    }));
  };

  const handleSendReply = async (e, messageId, replyText) => {
    e.preventDefault();
  
    if (!replyText.trim() || !user || !user.uid) {
      console.error("Reply text or user details are missing.");
      return;
    }
  
    try {
      const messageRef = doc(db, 'gyms', gymId, 'messages', messageId);
      const newReply = {
        senderId: user.uid,
        senderName: `${userFirstName} ${userLastName}`,
        message: replyText,
        timestamp: new Date()
      };

      const docSnap = await getDoc(messageRef);
      if (docSnap.exists()) {
        const currentReplies = docSnap.data().replies || [];
        const updatedReplies = [...currentReplies, newReply];
        await updateDoc(messageRef, {
          replies: updatedReplies
        });
      }

      console.log('Reply sent for messageId:', messageId);
      fetchMessages();
    } catch (error) {
      console.error('Error sending reply:', error);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (newMessageTopic.trim() !== '' && newMessageBody.trim() !== '') {
      const messagesRef = collection(db, 'gyms', gymId, 'messages');
      await addDoc(messagesRef, {
        senderId: user.uid,
        senderName: `${userFirstName} ${userLastName}`,
        topic: newMessageTopic,
        body: newMessageBody,
        timestamp: serverTimestamp(),
        tags: selectedTags || [],
        replies: [],
        isPinned: false
      });
      setNewMessageTopic('');
      setNewMessageBody('');
      setSelectedTags([]);
      fetchMessages();
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!hasGym) {
    return (
      <Box p={4}>
        <Text>You are not assigned to a gym.</Text>
        <Button onClick={() => navigate('/userprofile')}>Go to Profile</Button>
      </Box>
    );
  }

  return (
    <Box p={2} bg={bgColor} minHeight="100vh">
      <Flex align="center" justify="center" mb={2}>
        <Heading as="h2" size="lg" color={textColor} textAlign="center">
          Welcome to the {gymData?.name} Common Room!
        </Heading>
      </Flex>
  
      <Grid templateColumns={['1fr', '1fr 3fr']} gap={2}>
        <GridItem>
          <Box bg={boxBgColor} p={2} borderRadius="md" boxShadow="sm">
            <Heading as="h3" size="sm" mb={1} color={textColor}>
              Upcoming Events
            </Heading>
            <VStack spacing={1} align="stretch" fontSize="xs">
  {upcomingEvents.map((event) => (
    <Box
      key={event.id}
      borderWidth="1px"
      borderRadius="md"
      p={1}
      bg="white"
      boxShadow="sm"
    >
      {user.uid === gymData?.captain ? (
        <>
          <Text fontWeight="bold" color={textColor} fontSize="xs">
            Event:
          </Text>
          <Input
            value={event.title}
            onChange={(e) =>
              handleUpdateEvent({
                ...event,
                title: e.target.value,
              })
            }
            mb={1}
            size="xs"
          />
          <Text fontWeight="bold" color={textColor} fontSize="xs">
            Date:
          </Text>
          <Input
            value={event.date}
            onChange={(e) =>
              handleUpdateEvent({
                ...event,
                date: e.target.value,
              })
            }
            mb={1}
            size="xs"
          />
          <Button
            size="xs"
            onClick={() => handleDeleteEvent(event.id)}
            colorScheme="red"
          >
            Delete
          </Button>
        </>
      ) : (
        <>
          <Text fontWeight="bold" color={textColor} fontSize="xs">
            Event:
          </Text>
          <Text color={textColor} fontSize="xs">
            {event.title}
          </Text>
          <Text fontWeight="bold" color={textColor} fontSize="xs">
            Date:
          </Text>
          <Text color={textColor} fontSize="xs">
            {event.date}
          </Text>
        </>
      )}
    </Box>
  ))}
  {user.uid === gymData?.captain && (
    <Button onClick={handleAddEvent} colorScheme="blue" size="xs">
      Add Event
    </Button>
  )}
</VStack>
          </Box>
          <Box bg={boxBgColor} p={2} borderRadius="md" boxShadow="sm" mt={2}>
            <Heading as="h3" size="sm" mb={1} color={textColor}>
              Team Members
            </Heading>
            <AvatarGroup size="xs" max={3}>
              {teamMembers.map((member) => (
                <Avatar
                  key={member.id}
                  name={`${member.firstName} ${member.lastName}`}
                  src={member.avatarUrl}
                />
              ))}
            </AvatarGroup>
          </Box>
        </GridItem>
  
        <GridItem>
          <Box
            bg={boxBgColor}
            p={2}
            borderRadius="md"
            boxShadow="sm"
            maxHeight="calc(100vh - 150px)"
            overflowY="auto"
          >
            <VStack spacing={2} align="stretch">
              {messages.map((message) => (
                <Box
                  key={message.id}
                  borderWidth="1px"
                  borderRadius="md"
                  p={1}
                  bg={message.replies?.length > 0 ? 'blue.50' : 'white'}
                  boxShadow="sm"
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text fontWeight="bold" color={textColor} fontSize="sm">
                      {message.senderName}
                    </Text>
                    <Flex>
                      <Text fontSize="xs" color={textColor} mr={1}>
                        {message.timestamp?.toDate().toLocaleString()}
                      </Text>
                      {(user.uid === message.senderId ||
                        (gymData && user.uid === gymData.captain)) && (
                        <IconButton
                          icon={<DeleteIcon />}
                          aria-label="Delete message"
                          size="xs"
                          onClick={() =>
                            handleDeleteMessage(message.id, message.senderId)
                          }
                          colorScheme="red"
                          variant="ghost"
                        />
                      )}
                    </Flex>
                  </Flex>
                  <Text fontWeight="bold" color={textColor} mt={1} fontSize="sm">
                    {message.topic}
                  </Text>
                  <Text color={textColor} mt={1} fontSize="sm">
                    {message.body}
                  </Text>
                  <Flex mt={1}>
                    {message.tags?.map((tag) => (
                      <Tag
                        key={tag}
                        size="sm"
                        mr={1}
                        colorScheme="blue"
                        variant="subtle"
                      >
                        <TagLabel>{tag}</TagLabel>
                      </Tag>
                    ))}
                  </Flex>
                  {message.replies?.length > 0 && (
                    <Button
                      size="xs"
                      onClick={() => toggleReplies(message.id)}
                      rightIcon={
                        <Badge colorScheme="blue">
                          {message.replies.length}
                        </Badge>
                      }
                      colorScheme="blue"
                      variant="link"
                      mt={1}
                    >
                      {activeMessageId === message.id
                        ? 'Hide Replies'
                        : 'Show Replies'}
                    </Button>
                  )}
                  <Collapse in={activeMessageId === message.id}>
                    {message.replies?.slice(0, 3).map((reply, index) => (
                      <Box key={index} mt={1} ml={2}>
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Text fontWeight="bold" color={textColor} fontSize="xs">
                            {reply.senderName}
                          </Text>
                          <Text fontSize="xs" color={textColor}>
                            {reply.timestamp?.toDate().toLocaleString()}
                          </Text>
                        </Flex>
                        <Text color={textColor} fontSize="xs">
                          {reply.message}
                        </Text>
                      </Box>
                    ))}
                    {message.replies?.length > 3 && (
                      <Button
                        size="xs"
                        onClick={() => toggleReplies(message.id)}
                        colorScheme="blue"
                        variant="link"
                        mt={1}
                      >
                        {activeMessageId === message.id
                          ? 'Hide Replies'
                          : 'Show More Replies'}
                      </Button>
                    )}
                  </Collapse>
                  <Box
                    as="form"
                    onSubmit={(e) =>
                      handleSendReply(e, message.id, message.replyText)
                    }
                    mt={1}
                  >
                    <Textarea
                      size="xs"
                      placeholder="Write a reply..."
                      value={message.replyText || ''}
                      onChange={(e) => handleReplyTextChange(e, message.id)}
                    />
                    <Button type="submit" size="xs" mt={1} colorScheme="blue">
                      Reply
                    </Button>
                  </Box>
                </Box>
              ))}
            </VStack>
            {hasMore && (
              <Button
                mt={2}
                width="100%"
                onClick={fetchMessages}
                isLoading={isLoading}
                loadingText="Loading..."
                colorScheme="blue"
                size="xs"
              >
                Load more
              </Button>
            )}
          </Box>
          {/* Form to add a new message */}
          <Box as="form" onSubmit={handleSendMessage} mt={2}>
            <Textarea
              placeholder="New message topic..."
              mb={1}
              value={newMessageTopic}
              onChange={(e) => setNewMessageTopic(e.target.value)}
              size="sm"
            />
            <Textarea
              placeholder="Type your message here..."
              mb={1}
              value={newMessageBody}
              onChange={(e) => setNewMessageBody(e.target.value)}
              size="sm"
            />
            <Button type="submit" colorScheme="blue" width="100%" size="sm">
              Send Message
            </Button>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default Team;