import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase-config';
import { collection, getDocs, doc, setDoc, Timestamp } from 'firebase/firestore';
import Calendar from 'react-calendar';
import { format } from 'date-fns';
import './AddTimeSlots.css';

const AddTimeSlots = () => {
  const [gyms, setGyms] = useState([]);
  const [selectedGym, setSelectedGym] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSlots, setSelectedSlots] = useState(new Set());
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGyms = async () => {
      try {
        const gymCollectionRef = collection(db, 'gyms');
        const gymDocs = await getDocs(gymCollectionRef);
        const gymsData = gymDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setGyms(gymsData);
      } catch (error) {
        console.error("Failed to fetch gyms:", error);
      }
    };
    fetchGyms();
  }, []);

  const toggleTimeSlotSelection = (timeSlot) => {
    setSelectedSlots(prevSelectedSlots => {
      const newSelection = new Set(prevSelectedSlots);
      if (newSelection.has(timeSlot)) {
        newSelection.delete(timeSlot);
      } else {
        newSelection.add(timeSlot);
      }
      return newSelection;
    });
  };

  const saveTimeSlots = async () => {
    if (!selectedGym || selectedSlots.size === 0) {
      alert("Please select a gym and at least one time slot.");
      return;
    }

    const formattedDateString = format(selectedDate, 'yyyy-MM-dd');

    const timeSlotsToSave = Array.from(selectedSlots).map(time => ({
      gymId: selectedGym,
      date: formattedDateString,
      time,
      createdAt: Timestamp.now(),
    }));

    try {
      await Promise.all(timeSlotsToSave.map(timeslot => {
        const timeslotDocId = `${selectedGym}_${formattedDateString}_${timeslot.time}`;
        const timeslotDocRef = doc(db, 'timeSlots', timeslotDocId);
        return setDoc(timeslotDocRef, {
          ...timeslot,
          createdAt: Timestamp.now(),
        });
      }));
      alert("Time slots saved successfully!");
      setSelectedSlots(new Set()); // Clear selections after saving
    } catch (error) {
      console.error("Error saving time slots:", error);
      alert("Failed to save time slots.");
    }
  };

  const handleGymSelect = event => {
    setSelectedGym(event.target.value);
  };

  const onDateChange = value => {
    setSelectedDate(value);
    setSelectedSlots(new Set()); // Reset selected slots when date changes
  };

  const minDate = new Date(); // Restrict past dates

  return (
    <div className="add-timeslots-container">
      <div className="header-container">
        <button className="back-button" onClick={() => navigate('/dashboard')}>
          Return to Dashboard
        </button>
        <h1>Add Time Slots for Gyms</h1>
        <label htmlFor="gym-select">Choose a gym:</label>
        <select id="gym-select" value={selectedGym} onChange={handleGymSelect}>
          <option value="">Select a Gym</option>
          {gyms.map(gym => (
            <option key={gym.id} value={gym.id}>{gym.name}</option>
          ))}
        </select>
        <Calendar
          onChange={onDateChange}
          value={selectedDate}
          minDate={minDate}
        />
        <div className="time-slots-wrapper">
          <h3>Select Time Slots for {format(selectedDate, 'PP')}</h3>
          <div className="time-slots">
            {Array.from({ length: 96 }, (_, i) => {
              const hour = Math.floor((i * 15) / 60) + 6; // slots from 6:00 to 21:45
              const minute = (i * 15) % 60;
              const timeSlot = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
              return (
                <button
                  key={timeSlot}
                  onClick={() => toggleTimeSlotSelection(timeSlot)}
                  className={`time-slot-button ${selectedSlots.has(timeSlot) ? 'selected' : ''}`}
                >
                  {timeSlot}
                </button>
              );
            })}
          </div>
        </div>
        <div className="save-button-container">
          <button onClick={saveTimeSlots} className="save-button">
            Save Time Slots
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTimeSlots;