import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase-config';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, doc, setDoc } from 'firebase/firestore';
import './SignUpForSlot.css';

const SignUpForSlot = () => {
  const [gyms, setGyms] = useState([]);
  const [selectedGym, setSelectedGym] = useState('');
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [slots, setSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();
  const [selectedMatches, setSelectedMatches] = useState(1);

  const handleGymChange = (e) => {
    setSelectedGym(e.target.value);
    setSelectedDate('');
    setSlots([]);
    setAvailableDates([]);
  };

  useEffect(() => {
    const fetchGyms = async () => {
      try {
        const gymsSnapshot = await getDocs(collection(db, 'gyms'));
        const gymsData = gymsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    
        // Filter gyms with available slots
        const gymsWithSlots = await Promise.all(
          gymsData.map(async (gym) => {
            const timeSlotsQuery = query(
              collection(db, 'timeSlots'),
              where('gymId', '==', gym.id)
            );
            const querySnapshot = await getDocs(timeSlotsQuery);
            return querySnapshot.size > 0 ? gym : null;
          })
        );
    
        setGyms(gymsWithSlots.filter(Boolean));
      } catch (error) {
        console.error("Failed to fetch gyms:", error);
      }
    };
    fetchGyms();
  }, []);

  useEffect(() => {
    const fetchAvailableDates = async () => {
      if (selectedGym) {
        setLoading(true);
        try {
          const timeSlotsQuery = query(
            collection(db, 'timeSlots'),
            where('gymId', '==', selectedGym)
          );

          const querySnapshot = await getDocs(timeSlotsQuery);
          const datesSet = new Set();
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            datesSet.add(data.date);
          });

          const dates = Array.from(datesSet).map(date => ({
            originalDate: date,
            formattedDate: formatDate(date)
          }));

          setAvailableDates(dates);
        } catch (error) {
          console.error("Failed to fetch available dates:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchAvailableDates();
  }, [selectedGym]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };
  const formatTime = (timeString) => {
    // Assuming timeString is in 'HH:MM' format
    const [hours, minutes] = timeString.split(':');
    let hoursInt = parseInt(hours, 10);
    const ampm = hoursInt >= 12 ? 'PM' : 'AM';
    hoursInt = hoursInt % 12;
    hoursInt = hoursInt ? hoursInt : 12; // the hour '0' should be '12'
    const formattedTime = hoursInt + ':' + minutes + ' ' + ampm;
    return formattedTime;
  };



  useEffect(() => {
    const fetchSlotsForDate = async () => {
      if (selectedGym && selectedDate) {
        setLoading(true);
        try {
          const timeSlotsQuery = query(
            collection(db, 'timeSlots'),
            where('gymId', '==', selectedGym),
            where('date', '==', selectedDate)
          );

          const querySnapshot = await getDocs(timeSlotsQuery);
          const slotsData = querySnapshot.docs.map(doc => {
            const slotData = doc.data();
            return {
              id: doc.id,
              ...slotData,
              time: formatTime(slotData.time), // Now using formatTime to convert to 12-hour format
              formattedDate: formatDate(slotData.date) // This remains unchanged
            };
          });
          setSlots(slotsData);
        } catch (error) {
          console.error("Failed to fetch slots:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchSlotsForDate();
  }, [selectedGym, selectedDate]);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const reserveSlot = async (slot) => {
    if (!user) {
      alert("You need to be logged in to reserve a slot.");
      return;
    }

    const numMatches = prompt("How many potential matches would you be willing to have during this 1-hour window? (1-5)");
    const parsedNumMatches = parseInt(numMatches, 10);

    if (isNaN(parsedNumMatches) || parsedNumMatches < 1 || parsedNumMatches > 5) {
      alert("Invalid input. Please enter a number between 1 and 5.");
      return;
    }

    setSelectedMatches(parsedNumMatches);

    setLoading(true);
    try {
      const reservations = [];

      for (let i = 0; i < parsedNumMatches; i++) {
        const reservationId = doc(collection(db, 'reservations')).id;
        const newReservationRef = doc(db, 'reservations', reservationId);

        const reservationData = {
          userId: user.uid,
          slotId: slot.id,
          gymId: slot.gymId,
          date: slot.date,
          time: slot.time,
          matchNumber: i + 1,
        };

        reservations.push(setDoc(newReservationRef, reservationData));
      }

      await Promise.all(reservations);

      alert(`You have reserved ${parsedNumMatches} slot(s) at ${slot.formattedDate} ${formatTime(slot.time)}.`);

    } catch (error) {
      console.error("Error reserving time slot:", error);
      alert("Failed to reserve slots. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, [auth]);

  return (
    <div className="sign-up-container">
      <button className="back-button" onClick={() => navigate('/dashboard')}>
        Return to Dashboard
      </button>
      <h2 className="sign-up-title">Sign Up for a Slot</h2>
      <div className="gym-select-container">
        <label htmlFor="gym-select" className="select-label">Choose a gym:</label>
        <select id="gym-select" className="select-input" value={selectedGym} onChange={handleGymChange}>
          <option value="">Select a Gym</option>
          {gyms.map((gym) => (
            <option key={gym.id} value={gym.id}>{gym.name}</option>
          ))}
        </select>
      </div>
      {availableDates.length > 0 && (
        <div className="date-select-container">
          <label htmlFor="date-select" className="select-label">Choose a date:</label>
          <select id="date-select" className="select-input" value={selectedDate} onChange={handleDateChange}>
            <option value="">Select a Date</option>
            {availableDates.map((date) => (
              <option key={date.originalDate} value={date.originalDate}>{date.formattedDate}</option>
            ))}
          </select>
        </div>
      )}
      <div className="slots-container">
        {loading ? (
          <p className="loading-message">Loading slots...</p>
        ) : slots.length === 0 ? (
          <div className="no-slots-message">No slots available for this date.</div>
        ) : (
          slots.map((slot) => (
            <div key={slot.id} className="time-slot">
              <span className="slot-time">{slot.formattedDate} {slot.time}</span>
              <button
                className="reserve-button"
                onClick={() => reserveSlot(slot)}
              >
                Reserve
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SignUpForSlot;