import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, writeBatch, doc, FieldValue } from 'firebase/firestore';
import { getAuth, updateProfile } from 'firebase/auth';

const UserVerificationStatus = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUserVerificationStatus();
  }, []);

  const fetchUserVerificationStatus = async () => {
    const firestore = getFirestore();
    const usersCollection = collection(firestore, 'users');
    const usersSnapshot = await getDocs(usersCollection);

    const usersData = usersSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setUsers(usersData);
  };

  const handleVerifyAllUsers = async () => {
    const firestore = getFirestore();
    const auth = getAuth();
    const batch = writeBatch(firestore);

    for (const user of users) {
      const userRef = doc(firestore, 'users', user.id);
      batch.update(userRef, { emailVerified: true });

      const authUser = auth.currentUser;
      if (authUser) {
        await updateProfile(authUser, { emailVerified: true });
      }
    }

    await batch.commit();
    fetchUserVerificationStatus();
  };

  const handleRemoveEmailVerificationField = async () => {
    const firestore = getFirestore();
    const batch = writeBatch(firestore);
  
    users.forEach((user) => {
      const userRef = doc(firestore, 'users', user.id);
      batch.update(userRef, { emailVerified: FieldValue.delete() });
    });
  
    await batch.commit();
    fetchUserVerificationStatus();
  };

  return (
    <div>
      <h2>User Verification Status</h2>
      <button onClick={handleVerifyAllUsers}>Verify All Users</button>
      <button onClick={handleRemoveEmailVerificationField}>Remove Email Verification Field</button>
      {users.map((user) => (
        <div key={user.id}>
          <p>Username: {user.username}</p>
          <p>Email Verification (Firestore): {user.emailVerified ? 'Verified' : 'Not Verified'}</p>
          <hr />
        </div>
      ))}
    </div>
  );
};

export default UserVerificationStatus;