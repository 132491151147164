import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy, getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { Link } from 'react-router-dom';
import { Box, ChakraProvider, extendTheme, Heading, List, ListItem, Flex, Text, Icon } from '@chakra-ui/react';
import { ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';
import { GiDaggers } from 'react-icons/gi';

const customTheme = extendTheme({
  fonts: {
    heading: 'Rajdhani, sans-serif',
    body: 'Rajdhani, sans-serif',
  },
});

const Rankings = () => {
  const [users, setUsers] = useState([]);

  const firstCompetitorUserIds = [
    'z5sIPnaxgOQWs3KILOhpRiwm7hg1',
    'fOWftNshXTY12qRgNGjS2wsDjoK2',
    'XqNUBBtpoTVBxXPEOl5n9DxU2Hy2',
    'PhsENdYFwNOnkGja56uCYDyTzDY2',
  ];

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const usersCollectionRef = query(collection(db, "users"), orderBy("elo", "desc"));
    const usersSnapshot = await getDocs(usersCollectionRef);
    const usersWithEloChange = await Promise.all(
      usersSnapshot.docs.map(async (docSnapshot) => {
        const userData = docSnapshot.data();
        if (userData.isActive === false) {
          return null;
        }
        const userEloHistoryRef = doc(db, 'userEloHistory', docSnapshot.id);
        const userEloHistorySnapshot = await getDoc(userEloHistoryRef);
        const prevElo = userEloHistorySnapshot.exists() ? userEloHistorySnapshot.data().elo : null;
        const eloChange = prevElo ? userData.elo - prevElo : null;
        return { ...userData, id: docSnapshot.id, eloChange };
      })
    );
    const filteredUsers = usersWithEloChange.filter(user => user !== null);
    console.log('Filtered Users:', filteredUsers); // Check the contents of filteredUsers
    setUsers(filteredUsers);
  };

  return (
    <ChakraProvider theme={customTheme}>
      <Box maxW="600px" mx="auto" p={4} bg="gray.50" borderRadius="md" boxShadow="md">
        <Heading size="lg" textAlign="center" mb={4}>Rankings</Heading>
        <Text fontSize="sm" color="gray.500" mb={4}>
          <Icon as={GiDaggers} color="red.500" boxSize={4} mr={1} />
          This icon represents the first competitors.
        </Text>
        <List spacing={3}>
          {users.map((user, index) => (
            <ListItem key={user.id} bg={index === 0 ? "green.100" : "white"} p={3} borderRadius="md" boxShadow="sm">
              <Flex direction={{ base: 'column', md: 'row' }} align="center">
                <Flex align="center" mb={{ base: 2, md: 0 }}>
                  <Text fontWeight="bold" mr={2}>{index + 1}.</Text>
                  <Text fontWeight="bold" mr={2}>
                    {`${user.firstName} ${user.middleInitial ? user.middleInitial + '.' : ''} ${user.lastName}`}
                  </Text>
                  {firstCompetitorUserIds.includes(user.id) && (
                    <Icon as={GiDaggers} color="red.500" boxSize={4} ml={2} />
                  )}
                </Flex>
                <Flex align="center" ml={{ base: 0, md: 'auto' }}>
                  <Text fontWeight="light" color="gray.600" mr={2}>
                    Elo: {user.elo}
                  </Text>
                  {user.eloChange > 0 ? (
                    <Icon as={ArrowUpIcon} color="green.500" boxSize={6} ml={2} />
                  ) : user.eloChange < 0 ? (
                    <Icon as={ArrowDownIcon} color="red.500" boxSize={6} ml={2} />
                  ) : null}
                </Flex>
              </Flex>
            </ListItem>
          ))}
        </List>
        <Link to="/dashboard">
          <Box as="button" mt={4} px={4} py={2} bg="blue.500" color="white" borderRadius="md">
            Back to Dashboard
          </Box>
        </Link>
      </Box>
    </ChakraProvider>
  );
};

export default Rankings;