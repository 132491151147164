import { useState, useEffect } from 'react';

function useTimer() {
  const [time, setTime] = useState(0);
  const [isTimerOn, setTimerOn] = useState(false);

  useEffect(() => {
    let intervalId = null;

    if (isTimerOn) {
      intervalId = setInterval(() => {
        setTime(prevTime => prevTime + 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isTimerOn]);

  const handleTimer = () => {
    setTimerOn(prevState => !prevState);
  };

  const resetTimer = () => {
    setTime(0);
    setTimerOn(false);
  };

  return { time, isTimerOn, handleTimer, resetTimer };
}

export default useTimer;