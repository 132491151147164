import React, { useState } from 'react';
import './Faq.css'; // Make sure to create a corresponding CSS file
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Faq = () => {
  // Array of FAQs
  const [faqs, setFaqs] = useState([
    { 
        question: 'What is an Elo System?', 
        answer: `The Elo rating system quantifies player skill in games like chess by awarding points based on match outcomes. Winning against a higher-rated opponent gains more points,
        adjusting each player's rating to reflect their current skill level accurately.`, 
        open: false 
      },

      { 
        question: 'How are you applying this to Jiu Jitsu?', 
        answer: `Competitors are assigned an Elo when they sign up, based off years of experiance. Matches are made on an one on one basis.  When a match is complete
        the difference in Elo as well as differences in weight and weather a submission was successfully applied will be taken into account to adjust both players Elo.`, 
        open: false 
      },

      { 
        question: 'How are weight differnces taken into account?', 
        answer: `Matches will be made with competitors with similar, but not exactly the same weight.  At the start of a match both weights will be taken.  The competitor with the higher 
        weight will receive less points for a win and lose more points for a loss, and vice a versa with their opponent.`, 
        open: false 
      },

      { 
        question: 'How are matches made?', 
        answer: `Competitors can search host gyms for availible time slots.  They can then reserve a time slot.  Competitors will then be matched up a week before the dates selected based
        off the closest in weight in Elo.  If no one close in weight and Elo is found the match will not be made.`, 
        open: false 
      },

      { 
        question: 'What is the Rule Set?', 
        answer: `See the Rules page on the main dashboard`, 
        open: false 
      },

      { 
        question: 'How are matches judged?', 
        answer: `A designated Ref will be present, the match will also be filmed for record and eventually posted on the PGR site.`, 
        open: false 
      },

      { 
        question: 'How many matches can you compete in per week?', 
        answer: `As many or as few as you'd like.  However, long periods of inactivty will result in slow degradation of Elo.`, 
        open: false 
      },



    // Add more questions here
  ]);
  const navigate = useNavigate(); // Initialize useNavigate
  // Function to toggle FAQ answer
  const toggleFAQ = index => {
    const newFaqs = faqs.map((faq, i) => {
      if (i === index) {
        faq.open = !faq.open;
      } else {
        faq.open = false;
      }
      return faq;
    });
    setFaqs(newFaqs);
  };

  return (
    <div className="faq-container">
      <h2>FAQs</h2>
      {faqs.map((faq, index) => (
        <div
          key={index}
          className={`faq ${faq.open ? 'open' : ''}`}
          onClick={() => toggleFAQ(index)}
        >
          <div className="faq-question">{faq.question}</div>
          <div className="faq-answer">{faq.open && faq.answer}</div>
        </div>
      ))}
    <button className="back-dashboard" onClick={() => navigate('/dashboard')}>Back to Dashboard</button>
    </div>
  );
};

export default Faq;
