import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../firebase-config';
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  Timestamp,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import {
  Box,
  VStack,
  Heading,
  Text,
  Flex,
  ChakraProvider,
  extendTheme,
  Grid,
  GridItem,
  Container,
  Spinner,
  Icon,
  Button,
  useBreakpointValue,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { GiWhistle, GiBlackKnightHelm, GiCenturionHelmet, GiBarracksTent, GiBooze, GiTrophyCup, GiAbacus, GiBloodySword, GiMedievalPavilion, GiAncientSword, GiStopwatch, GiCrossedSabres, GiEagleEmblem, GiCoronation, GiCrystalBall, GiBookshelf, GiInjustice, GiBlackBelt, GiCardDraw } from "react-icons/gi";

const customTheme = extendTheme({
  fonts: {
    heading: 'Rajdhani, sans-serif',
    body: 'Rajdhani, sans-serif',
  },
});

function InfoDialog({ isOpen, onClose, title, message }) {
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>
          <AlertDialogBody>
            <Text>{message}</Text>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

function Dashboard() {
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCaptain, setIsCaptain] = useState(false);
  const bgColor = 'white';

  const buttonSize = useBreakpointValue({ base: 'lg', sm: 'md', md: 'lg' });
  const iconSize = useBreakpointValue({ base: 8, sm: 6, md: 8 });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedConfig, setSelectedConfig] = useState(null);

  const [newSlotDialogOpen, setNewSlotDialogOpen] = useState(false);
  const [newSlotMessage, setNewSlotMessage] = useState('');

  useEffect(() => {
    console.log('Fetching user data...');
    setLoading(true);

    const user = auth.currentUser;
    const adminUids = ['ECJqIXCF23aJYRep95gbwyr6FI32', 'PAxEPLHUl8OtMJU8RN3ZgdQ1GIQ2', 'cFcO38roPPR7zlFOBFnve9mqOB73', 'M7wImlJrw2SIsxYgzp6WJmLVLX33'];

    if (user) {
      setIsAdmin(adminUids.includes(user.uid));
      const userDocRef = doc(db, 'users', user.uid);

      getDoc(userDocRef)
        .then((userDoc) => {
          if (userDoc.exists()) {
            const data = userDoc.data();
            console.log('User data:', data);
            setUserInfo({
              username: data.username || 'User',
              eloRating: data.elo || 1200,
              wins: Array.isArray(data.wins) ? data.wins.length : 0,
              losses: Array.isArray(data.losses) ? data.losses.length : 0,
              draws: Array.isArray(data.draws) ? data.draws.length : 0,
              firstName: data.firstName || '',
            });

            try {
              updateDoc(userDocRef, { lastLoginTimestamp: Timestamp.now().toMillis() });
            } catch (error) {
              console.error('Error updating user last login timestamp:', error);
            }

            // Check if the user is a captain
            const gymsCollection = collection(db, 'gyms');
            getDocs(query(gymsCollection, where('captain', '==', user.uid)))
              .then((gymsSnapshot) => {
                if (!gymsSnapshot.empty) {
                  setIsCaptain(true);
                }
              })
              .catch((error) => {
                console.error('Error checking if user is a captain:', error);
              });
          } else {
            console.log('No such document!');
            navigate('/');
          }
        })
        .catch((error) => {
          console.error('Error getting document:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.log('User not logged in');
      navigate('/');
      setLoading(false);
    }
  }, [auth.currentUser, navigate]);

  useEffect(() => {
    const fetchTimeSlots = async () => {
      console.log("Starting fetchTimeSlots function");
      setLoading(true);
      try {
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        const previousLoginTimestamp = userDoc.data().lastLoginTimestamp || 0;
        console.log("User's previous login timestamp:", previousLoginTimestamp);

        const timeSlotsSnapshot = await getDocs(collection(db, 'timeSlots'));
        const allTimeSlots = timeSlotsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAtMillis: doc.data().createdAt.toMillis(),
        }));

        console.log("Fetched all time slots:", allTimeSlots);

        let slotsToNotify = JSON.parse(localStorage.getItem('acknowledgedSlots')) || {};
        console.log('Slots to notify:', slotsToNotify);

        const gymsSnapshot = await getDocs(collection(db, 'gyms'));
        const gymsData = gymsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log('Gyms data:', gymsData);

        let hasNewSlots = false;

        for (const slot of allTimeSlots) {
          console.log('Processing slot:', slot);
          if (!slotsToNotify[slot.id]) {
            const gym = gymsData.find(gym => gym.id === slot.gymId);
            const gymName = gym ? gym.name : 'Unknown Gym';

            const slotDateTime = new Date(slot.date + ' ' + slot.time);
            const message = `New slot open on ${slotDateTime.toLocaleDateString()} at ${slotDateTime.toLocaleTimeString()} at ${gymName}! Click "Find a Match" to reserve!`;
            console.log("New slot message:", message);
            setNewSlotMessage(message);
            hasNewSlots = true;
            slotsToNotify[slot.id] = true;
            localStorage.setItem('acknowledgedSlots', JSON.stringify(slotsToNotify));

            break;
          }
        }

        console.log('Has new slots?', hasNewSlots);
        if (hasNewSlots) {
          setNewSlotDialogOpen(true);
        }

        await updateDoc(userDocRef, { lastLoginTimestamp: Timestamp.now().toMillis() });

      } catch (error) {
        console.error('Error fetching time slots:', error);
      } finally {
        setLoading(false);
      }
    };

    if (auth.currentUser) {
      fetchTimeSlots();
    }
  }, [auth.currentUser]);

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (!userInfo) {
    return <Text>User not found or not logged in.</Text>;
  }

  const buttonConfigurations = [
    { to: "/signupforslot", icon: GiCrossedSabres, text: "Find a Match", color: "#cf0408", message: "This is where you'll look for Slots to Reserve.  When you Reserve a slot you are put into a pool of potential opponents, if a suitable match for weight and skill are found a match might be made.  These will be announced a week before the selected timeslot." },
    { to: "/userlookup", icon: GiCrystalBall, text: "User Lookup", message: "Search for a specific user and view their profile information." },
    { to: "/UpcomingEvents", icon: GiAncientSword, text: "Your Upcoming Matches", message: "View your upcoming matches and their details." },
    { to: "/GymsMap", icon: GiMedievalPavilion, text: "Gyms Map", message: "Explore the map of gyms and their locations.  You can also see open timeslots here." },
    { to: "/Data", icon: GiBlackBelt, text: "Data", message: "All our match data will be found here." },
    { to: "/eloexplanation", icon: GiAbacus, text: "How Elo Works!", message: "Learn about the Elo rating system and how it is calculated.  Examples are given." },
    { to: "/rankings", icon: GiCoronation, text: "Rankings", message: "Check out the rankings of players based on their Elo ratings." },
    { to: "/team", icon: GiBarracksTent, text: "Your Team", message: "View information about your team and its members.  If you have not assigned a gym go to your profile and select one, that gyms designated captain will be prompted to approve you." },
    { to: "/Rules", icon: GiInjustice, text: "Rules System", message: "Read about the rules, they are a bit different then most jiu jitsu rulesets." },
    { to: "/MatchHistory", icon: GiBloodySword, text: "Match History", message: "Review your match history and past match results." },
    { to: "/Faq", icon: GiBookshelf, text: "FAQ", message: "Find answers to frequently asked questions." },
    { to: "/BecomeRef", icon: GiWhistle, text: "Become a Ref", message: "Learn how to become a referee and officiate matches." },
  ];

  const adminConfigurations = [
    isCaptain && { to: "/Captain", icon: GiCenturionHelmet, text: "Captain Dashboard", message: "Access the captain's dashboard to manage your team." },
    isAdmin && { to: "/AdminDashboard", icon: GiEagleEmblem, text: "Admin Dashboard", message: "Access the admin dashboard to manage the system." },
    isAdmin && { to: "/RefDashboard", icon: GiStopwatch, text: "Ref Dashboard", message: "Access the referee dashboard to manage matches." },
  ];

  const allConfigurations = [...buttonConfigurations, ...adminConfigurations.filter(Boolean)];

  const handleInfoClick = (config) => {
    setSelectedConfig(config);
    onOpen();
  };

  const handleNewSlotDialogClose = () => {
    setNewSlotDialogOpen(false);
  };

  return (
    <ChakraProvider theme={customTheme}>
      <Box bg={bgColor} minHeight="100vh">
        <Container maxW="container.sm" py={8} px={2} centerContent>
          <VStack spacing={6} align="center" w="full">
            <Box w="100%">
              <Flex justify="flex-end" mb={2}>
                <Link to="/UserProfile">
                  <Button leftIcon={<Icon as={GiBlackKnightHelm} />} variant="ghost" _hover={{ bg: 'gray.100' }} size="lg">
                    Profile
                  </Button>
                </Link>
              </Flex>
              <Heading as="h1" size="xl" textAlign="center">
                {userInfo.username}, Slots are OPEN! Click, Find a Match!
              </Heading>
            </Box>
            <Box
              bg="white"
              p={6}
              borderRadius="lg"
              boxShadow="md"
              textAlign="center"
              width="100%"
            >
              <Flex align="center" justify="center" mb={4}>
                <Text fontSize="2xl" fontWeight="bold" mr={2}>
                  Current ELO:
                </Text>
                <Text fontSize="3xl" fontWeight="bold" color="red.500">
                  {userInfo.eloRating}
                </Text>
              </Flex>
              <Flex align="center" justify="center">
                <Text fontSize="lg" mr={4}>
                  <Icon as={GiTrophyCup} color="green.500" mr={1} />
                  Wins: {userInfo.wins}
                </Text>
                <Text fontSize="lg">
                  <Icon as={GiBooze} color="red.500" mr={2} />
                  Losses: {userInfo.losses}
                </Text>
                <Text fontSize="lg">
                  <Icon as={GiCardDraw} color="black" mr={2} />
                  Draws: {userInfo.draws}
                </Text>
              </Flex>
            </Box>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)"
              }}
              gap={{ base: 4, sm: 6, md: 8 }}
              justifyContent="center"
              alignItems="center"
            >
              {allConfigurations.map((config) => (
                <GridItem key={config.to}>
                  <Flex align="center">
                    <Link to={config.to}>
                      <Button
                        fontSize={{ base: "md", sm: "lg" }}
                        size={buttonSize}
                        width="100%"
                        height="100%"
                        variant="ghost"
                        colorScheme="Black"
                        borderRadius="md"
                        leftIcon={<Icon as={config.icon} boxSize={iconSize} color={config.color} />}
                        justifyContent="flex-start"
                        fontWeight="bold"
                      >
                        {config.text}
                      </Button>
                    </Link>
                    <IconButton
                      ml={2}
                      size="sm"
                      icon={<AiOutlineQuestionCircle />}
                      onClick={() => handleInfoClick(config)}
                      variant="ghost"
                      _hover={{ bg: 'gray.100' }}
                    />
                  </Flex>
                </GridItem>
              ))}
            </Grid>
            <InfoDialog
              isOpen={isOpen}
              onClose={onClose}
              title={selectedConfig?.text || ''}
              message={selectedConfig?.message || ''}
            />
            <AlertDialog isOpen={newSlotDialogOpen} onClose={handleNewSlotDialogClose}>
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader>New Time Slot Available!</AlertDialogHeader>
                  <AlertDialogBody>
                    <Text>{newSlotMessage}</Text>
                    <Button mt={4} colorScheme="blue" onClick={handleNewSlotDialogClose}>
                      OK
                    </Button>
                  </AlertDialogBody>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </VStack>
        </Container>
      </Box>
    </ChakraProvider>
  );
}

export default Dashboard;
