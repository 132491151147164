import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
  HStack,
  IconButton,
  useMediaQuery,
  ButtonGroup,
} from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { db } from '../firebase-config';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import useTimer from './useTimer';
import Select from 'react-select';

function RefereeComponent() {
  const { time, isTimerOn, handleTimer, resetTimer } = useTimer();
  const [users, setUsers] = useState([]);
  const [selectedCompetitorOne, setSelectedCompetitorOne] = useState(null);
  const [selectedCompetitorTwo, setSelectedCompetitorTwo] = useState(null);
  const [weightOne, setWeightOne] = useState('');
  const [weightTwo, setWeightTwo] = useState('');
  const [pointsOne, setPointsOne] = useState(0);
  const [pointsTwo, setPointsTwo] = useState(0);
  const [submission, setSubmission] = useState('');
  const [customSubmission, setCustomSubmission] = useState('');
  const [winner, setWinner] = useState('');
  const [matchDate, setMatchDate] = useState('');
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const userData = await getDocs(usersCollection);
      setUsers(userData.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchUsers();
  }, []);

  const saveResult = async () => {
    if (isTimerOn) {
      alert("Please stop the timer before saving the result.");
      return;
    }

    if (!selectedCompetitorOne || !selectedCompetitorTwo) {
      alert("Please select both competitors before saving the result.");
      return;
    }

    if (!winner) {
      alert("Please select the winner before saving the result.");
      return;
    }

    if (!matchDate) {
      alert("Please select the match date before saving the result.");
      return;
    }

    const parsedWeightOne = parseFloat(weightOne);
    const parsedWeightTwo = parseFloat(weightTwo);

    if (isNaN(parsedWeightOne) || isNaN(parsedWeightTwo)) {
      alert("Please enter valid numeric weights for both competitors.");
      return;
    }

    const matchData = {
      competitorOne: selectedCompetitorOne ? selectedCompetitorOne.value : '',
      competitorTwo: selectedCompetitorTwo ? selectedCompetitorTwo.value : '',
      weightOne: parsedWeightOne,
      weightTwo: parsedWeightTwo,
      pointsOne,
      pointsTwo,
      submission: submission !== 'Other' ? submission : customSubmission,
      duration: time,
      winner,
      date: matchDate,
    };

    try {
      const matchesCollection = collection(db, 'matches');
      await addDoc(matchesCollection, matchData);
      alert("Match result saved successfully!");
      resetForm();
    } catch (error) {
      console.error("Error saving match result:", error);
      alert("An error occurred while saving the match result.");
    }
  };

  const resetForm = () => {
    setSelectedCompetitorOne(null);
    setSelectedCompetitorTwo(null);
    setWeightOne('');
    setWeightTwo('');
    setPointsOne(0);
    setPointsTwo(0);
    setSubmission('');
    setCustomSubmission('');
    setWinner('');
    setMatchDate('');
    resetTimer();
  };

  return (
    <VStack spacing={4} p={4}>
      <Box w="100%">
        <Button
          onClick={handleTimer}
          size="lg"
          colorScheme="blue"
          w="100%"
          h={isLargerThan600 ? "100px" : "80px"}
          fontSize={isLargerThan600 ? "4xl" : "2xl"}
        >
          {isTimerOn ? 'Stop' : 'Start'} Timer
        </Button>
        <Text fontSize="2xl" textAlign="center" mt={2}>
          Match Time: {time} seconds
        </Text>
        <Button
          onClick={resetTimer}
          size="md"
          colorScheme="red"
          mt={2}
        >
          Reset Timer
        </Button>
      </Box>
      <HStack w="100%" spacing={4}>
        <VStack w="50%" spacing={2}>
          <FormControl>
          <FormLabel>Competitor 1</FormLabel>
            <Select
              value={selectedCompetitorOne}
              onChange={setSelectedCompetitorOne}
              options={users.map(user => ({
                value: user.id,
                label: `${user.firstName} ${user.lastName}`
              }))}
              placeholder="Search and select"
            />
            <Input
              placeholder="Enter weight"
              value={weightOne}
              onChange={(e) => setWeightOne(e.target.value)}
              mt={2}
              isRequired
            />
          </FormControl>
          <HStack>
            <Text fontSize="xl">
              {selectedCompetitorOne?.label || ''} Points: {pointsOne}
            </Text>
            <IconButton
              onClick={() => setPointsOne(pointsOne + 1)}
              icon={<AddIcon />}
              size="lg"
              colorScheme="green"
            />
            <IconButton
              onClick={() => setPointsOne(Math.max(0, pointsOne - 1))}
              icon={<MinusIcon />}
              size="sm"
            />
          </HStack>
        </VStack>
        <VStack w="50%" spacing={2}>
          <FormControl>
            <FormLabel>Competitor 2</FormLabel>
            <Select
              value={selectedCompetitorTwo}
              onChange={setSelectedCompetitorTwo}
              options={users.map(user => ({
                value: user.id,
                label: `${user.firstName} ${user.lastName}`
              }))}
              placeholder="Search and select"
            />
            <Input
              placeholder="Enter weight"
              value={weightTwo}
              onChange={(e) => setWeightTwo(e.target.value)}
              mt={2}
              isRequired
            />
          </FormControl>
          <HStack>
            <Text fontSize="xl">
              {selectedCompetitorTwo?.label || ''} Points: {pointsTwo}
            </Text>
            <IconButton
              onClick={() => setPointsTwo(pointsTwo + 1)}
              icon={<AddIcon />}
              size="lg"
              colorScheme="green"
            />
            <IconButton
              onClick={() => setPointsTwo(Math.max(0, pointsTwo - 1))}
              icon={<MinusIcon />}
              size="sm"
            />
          </HStack>
        </VStack>
      </HStack>
      <FormControl>
  <FormLabel>Submission Type</FormLabel>
  <Select
  value={submission}
  onChange={(selectedOption) => {
    setSubmission(selectedOption.value);
    if (selectedOption.value !== 'Other') {
      setCustomSubmission('');
    }
  }}
  options={[
    { value: 'None', label: 'None' },
    { value: 'Rear Naked Choke', label: 'Rear Naked Choke' },
    { value: 'Guillotine Choke', label: 'Guillotine Choke' },
    { value: 'Triangle Choke', label: 'Triangle Choke' },
    { value: 'Arm Triangle Choke', label: 'Arm Triangle Choke' },
    { value: 'Ezekiel Choke', label: 'Ezekiel Choke' },
    { value: 'Loop Choke', label: 'Loop Choke' },
    { value: 'Bow and Arrow Choke', label: 'Bow and Arrow Choke' },
    { value: 'Baseball Bat Choke', label: 'Baseball Bat Choke' },
    { value: 'North-South Choke', label: 'North-South Choke' },
    { value: 'Anaconda Choke', label: 'Anaconda Choke' },
    { value: "D'arce Choke", label: "D'arce Choke" },
    { value: 'Cross Collar Choke', label: 'Cross Collar Choke' },
    { value: 'Gogoplata', label: 'Gogoplata' },
    { value: 'Peruvian Necktie', label: 'Peruvian Necktie' },
    { value: 'Armbar', label: 'Armbar' },
    { value: 'Kimura', label: 'Kimura' },
    { value: 'Americana', label: 'Americana' },
    { value: 'Omoplata', label: 'Omoplata' },
    { value: 'Kneebar', label: 'Kneebar' },
    { value: 'Heel Hook', label: 'Heel Hook' },
    { value: 'Toe Hold', label: 'Toe Hold' },
    { value: 'Ankle Lock', label: 'Ankle Lock' },
    { value: 'Wrist Lock', label: 'Wrist Lock' },
    { value: 'Twister', label: 'Twister' },
    { value: 'Bicep Slicer', label: 'Bicep Slicer' },
    { value: 'Calf Slicer', label: 'Calf Slicer' },
    { value: 'Banana Split', label: 'Banana Split' },
    { value: 'Electric Chair', label: 'Electric Chair' },
    { value: 'Flying Armbar', label: 'Flying Armbar' },
    { value: 'Other', label: 'Other (Specify)' },
  ]}
  placeholder="Select submission type"
/>
  {submission === 'Other' && (
    <Input
      placeholder="Custom submission"
      value={customSubmission}
      onChange={(e) => setCustomSubmission(e.target.value)}
      mt={2}
    />
  )}
</FormControl>
      <FormControl>
        <FormLabel>Select Winner</FormLabel>
        <ButtonGroup isAttached variant="outline">
          <Button
            colorScheme={winner === 'competitorOne' ? 'blue' : 'gray'}
            onClick={() => setWinner('competitorOne')}
          >
            {selectedCompetitorOne?.label || 'Competitor 1'}
          </Button>
          <Button
            colorScheme={winner === 'competitorTwo' ? 'blue' : 'gray'}
            onClick={() => setWinner('competitorTwo')}
          >
            {selectedCompetitorTwo?.label || 'Competitor 2'}
          </Button>
        </ButtonGroup>
      </FormControl>
      <FormControl>
  <FormLabel>Match Date</FormLabel>
  <Input
    type="date"
    value={matchDate}
    onChange={(e) => setMatchDate(e.target.value)}
    isRequired
  />
</FormControl>
      <Button onClick={saveResult} colorScheme="blue">
        Save Result
      </Button>
    </VStack>
  );
}

export default RefereeComponent;