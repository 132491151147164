import React, { useState, useEffect } from 'react'; // Include useEffect
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, reload } from "firebase/auth";
import { getFirestore, doc, setDoc, collection, getDocs } from "firebase/firestore"; // Include collection and getDocs
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase-config'; 
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    Textarea,
    Text,
    useToast,
    Heading,
    SimpleGrid,
    Flex,
    Spacer,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

const RegistrationForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        sex: '',
        beltRank: '',
        yearsOfExperience: '',
        competitionWeight: '',
        weight: '',
        gym: '',
        bio: '',
        region: 'Southern California',
    });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const toast = useToast();
    const [weightInfoAcknowledged, setWeightInfoAcknowledged] = useState(false);
    const { isOpen: isWeightModalOpen, onOpen: onWeightModalOpen, onClose: onWeightModalClose } = useDisclosure();
    const [gyms, setGyms] = useState([]);

     
   


    // Add useEffect hook to fetch gyms
    useEffect(() => {
        const fetchGyms = async () => {
          const gymsCollection = collection(db, 'gyms');
          const gymsSnapshot = await getDocs(gymsCollection);
          const gymsList = gymsSnapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
          setGyms(gymsList);
        };
      
        fetchGyms();
      }, [db]); // db is now added as a dependency

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const calculateElo = (yearsOfExperience) => {
        const minElo = 1000;
        const maxElo = 2000;
        const maxYears = 10;
        let elo = minElo + (yearsOfExperience / maxYears) * (maxElo - minElo);
        return Math.round(elo);
    };

    const validateForm = () => {
        let formIsValid = true;
        let errors = {};

        if (!formData.firstName.trim()) {
            errors.firstName = 'Please enter your first name.';
            formIsValid = false;
        }

        if (!formData.lastName.trim()) {
            errors.lastName = 'Please enter your last name.';
            formIsValid = false;
        }

        if (!formData.username.trim()) {
            errors.username = 'Please enter a username.';
            formIsValid = false;
        }

        if (!formData.sex) {
            errors.sex = 'Please select your sex.';
            formIsValid = false;
        }

        if (!formData.email.trim()) {
            errors.email = 'Please enter your email.';
            formIsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Please enter a valid email.';
            formIsValid = false;
        }

        if (!formData.password) {
            errors.password = 'Please enter a password.';
            formIsValid = false;
        } else if (formData.password.length < 6) {
            errors.password = 'Password must be at least 6 characters.';
            formIsValid = false;
        }

        if (formData.password !== formData.confirmPassword) {
            errors.confirmPassword = 'Passwords do not match.';
            formIsValid = false;
        }

        if (!formData.beltRank) {
            errors.beltRank = 'Please select your belt rank.';
            formIsValid = false;
        }

        if (!formData.yearsOfExperience) {
            errors.yearsOfExperience = 'Please select your years of experience.';
            formIsValid = false;
        }

        if (!formData.weight) {
            errors.weight = 'Please enter your weight.';
            formIsValid = false;
        } else if (formData.weight < 50 || formData.weight > 300) {
            errors.weight = 'Weight must be between 50 and 300 pounds.';
            formIsValid = false;
        }

        setErrors(errors);
        return formIsValid;
    };

    const [isRegistered, setIsRegistered] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [isCheckingVerification, setIsCheckingVerification] = useState(false);

    const handleRegister = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
          return;
        }
      
        const auth = getAuth();
        const firestore = getFirestore();
      
        try {
          // Create the user account with email and password
          const userCredential = await createUserWithEmailAndPassword(
            auth,
            formData.email,
            formData.password
          );
          const user = userCredential.user;
      
          // Sending a verification email to the user
          await sendEmailVerification(user);
          toast({
            title: 'Verification Email Sent',
            description: 'A verification email has been sent. Please check your email to verify your account.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
      
          // Calculate the ELO based on years of experience
          let experienceElo = 1000; // Default Elo
          if (formData.yearsOfExperience) {
            const years = parseInt(formData.yearsOfExperience, 10);
            experienceElo = !isNaN(years) && years > 0 ? calculateElo(years) : 1000;
          }
      
          // Parse the weight and ensure it's a valid number
          const weight = parseInt(formData.weight, 10);
          if (isNaN(weight) || weight < 50 || weight > 300) {
            console.error("Invalid weight:", formData.weight);
            throw new Error("Invalid weight provided");
          }
      
          // Remove password and confirmPassword before saving to Firestore
          const { password, confirmPassword, ...userDataForFirestore } = formData;
      
          const userData = {
            ...userDataForFirestore,
            sex: formData.sex,
            elo: experienceElo,
       
            weight,
            emailVerified: false,
            ...(formData.gym && { gymRequest: { gym: formData.gym, status: 'pending' } }),
          };
      
          // Saving the user data to Firestore
          await setDoc(doc(firestore, "users", user.uid), userData);
      
          // Indicating successful registration and prompting for email verification
          setIsRegistered(true);
        } catch (error) {
          setErrors(prevErrors => ({ ...prevErrors, firebase: error.message }));
          toast({
            title: 'Registration Error',
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      };

    const checkEmailVerification = async () => {
        setIsCheckingVerification(true); // Start checking
        const auth = getAuth();
        if (auth.currentUser) {
            try {
                await reload(auth.currentUser);
                setIsCheckingVerification(false); // Done checking
                if (auth.currentUser.emailVerified) {
                    setIsEmailVerified(true);
                    // Update the user's email verification status in Firestore
                    const firestore = getFirestore();
                    await setDoc(doc(firestore, "users", auth.currentUser.uid), {
                        emailVerified: true,
                    }, { merge: true });
                    // Maybe add a slight delay or a success message here before redirecting
                    setTimeout(() => {
                        navigate('/dashboard', { state: { userId: auth.currentUser.uid } });
                    }, 1000); // Adjust time as needed
                } else {
                    toast({
                        title: 'Email Not Verified',
                        description: 'Your email is not verified. Please verify your email before proceeding.',
                        status: 'warning',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                setIsCheckingVerification(false); // Ensure this is set even if there's an error
                console.error("Error reloading user:", error);
                toast({
                    title: 'Verification Error',
                    description: 'An error occurred while checking verification status. Please try again.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    const bgColor = useColorModeValue('gray.100', 'gray.700');
    const boxBgColor = useColorModeValue('white', 'gray.800');
    const buttonBgColor = useColorModeValue('blue.500', 'blue.200');
    const buttonHoverBgColor = useColorModeValue('blue.600', 'blue.300');

    return (
        <Flex minHeight="100vh" align="center" justify="center" bg={bgColor}>
            <Box
                borderWidth={1}
                px={4}
                width="full"
                maxWidth="500px"
                borderRadius={4}
                textAlign="center"
                boxShadow="lg"
                p={6}
                m={8}
                bg={boxBgColor}
            >
                <Box textAlign="center">
                    <Heading as="h2" size="xl" mt={6} mb={8}>
                        Register
                    </Heading>
                </Box>
                <Box my={8} textAlign="left">
                    <form onSubmit={handleRegister}>
                        <SimpleGrid columns={2} spacing={4}>
                            <FormControl id="firstName">
                                <FormLabel>First Name</FormLabel>
                                <Input
                                    type="text"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    isInvalid={!!errors.firstName}
                                />
                                <Text color="red.500">{errors.firstName}</Text>
                            </FormControl>

                            <FormControl id="lastName">
                                <FormLabel>Last Name</FormLabel>
                                <Input
                                    type="text"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    isInvalid={!!errors.lastName}
                                />
                                <Text color="red.500">{errors.lastName}</Text>
                            </FormControl>
                        </SimpleGrid>

                        <FormControl id="username" mt={4}>
                            <FormLabel>Username</FormLabel>
                            <Input
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={formData.username}
                                onChange={handleChange}
                                isInvalid={!!errors.username}
                            />
                            <Text color="red.500">{errors.username}</Text>
                        </FormControl>

                        <FormControl id="email" mt={4}>
                            <FormLabel>Email</FormLabel>
                            <Input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                            />
                            <Text color="red.500">{errors.email}</Text>
                        </FormControl>

                        <SimpleGrid columns={2} spacing={4} mt={4}>
                            <FormControl id="password">
                                <FormLabel>Password</FormLabel>
                                <Input
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    isInvalid={!!errors.password}
                                />
                                <Text color="red.500">{errors.password}</Text>
                            </FormControl>

                            <FormControl id="confirmPassword">
                                <FormLabel>Confirm Password</FormLabel>
                                <Input
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    isInvalid={!!errors.confirmPassword}
                                />
                                <Text color="red.500">{errors.confirmPassword}</Text>
                            </FormControl>
                        </SimpleGrid>

                        <FormControl id="beltRank" mt={4}>
                            <FormLabel>Belt Rank</FormLabel>
                            <Select
                                name="beltRank"
                                value={formData.beltRank}
                                onChange={handleChange}
                                placeholder="Select Belt Rank"
                                isInvalid={!!errors.beltRank}
                            >
                                <option value="White">White</option>
                                <option value="Blue">Blue</option>
                                <option value="Purple">Purple</option>
                                <option value="Brown">Brown</option>
                                <option value="Black">Black</option>
                            </Select>
                            <Text color="red.500">{errors.beltRank}</Text>
                        </FormControl>

                        <FormControl id="yearsOfExperience" mt={4}>
                            <FormLabel>Years of Experience</FormLabel>
                            <Select
                                name="yearsOfExperience"
                                value={formData.yearsOfExperience}
                                onChange={handleChange}
                                placeholder="Select Years of Experience"
                                isInvalid={!!errors.yearsOfExperience}
                            >
                                {Array.from({ length: 10 }, (_, i) => (
                                    <option key={i} value={i + 1}>{i + 1}</option>
                                ))}
                                <option value="10+">10+</option>
                            </Select>
                            <Text color="red.500">{errors.yearsOfExperience}</Text>
                        </FormControl>

                        <FormControl id="weight" mt={4}>
                            <FormLabel>Weight (lbs)</FormLabel>
                            <Input
                                type="number"
                                name="weight"
                                placeholder="Weight"
                                min="50"
                                max="300"
                                value={formData.weight}
                                onChange={handleChange}
                                isInvalid={!!errors.weight}
                                onFocus={() => {
                                    if (!weightInfoAcknowledged) {
                                        onWeightModalOpen();
                                    }
                                }}
                            />
                            <Text color="red.500">{errors.weight}</Text>
                        </FormControl>

                        <FormControl id="gym" mt={4}>


                        <FormControl id="sex" mt={4}>
    <FormLabel>Sex</FormLabel>
    <Select
        name="sex"
        value={formData.sex}
        onChange={handleChange}
        placeholder="Select Sex"
        isInvalid={!!errors.sex}
    >
        <option value="M">Male</option>
        <option value="F">Female</option>
    </Select>
    <Text color="red.500">{errors.sex}</Text>
</FormControl>


  <FormLabel>Gym (optional)</FormLabel>
  <Select
    name="gym"
    value={formData.gym}
    onChange={handleChange}
    placeholder="Select a gym (optional)"
  >
    {gyms.map((gym) => (
      <option key={gym.id} value={gym.id}>
        {gym.name}
      </option>
    ))}
  </Select>
</FormControl>

                        <FormControl id="bio" mt={4}>
                            <FormLabel>Bio</FormLabel>
                            <Textarea
                                name="bio"
                                placeholder="Tell us about yourself"
                                value={formData.bio}
                                onChange={handleChange}
                            />
                        </FormControl>

                        <FormControl id="region" mt={4}>
                            <FormLabel>Region</FormLabel>
                            <Select
                                name="region"
                                value={formData.region}
                                onChange={handleChange}
                            >
                                <option value="Southern California">Southern California</option>
                            </Select>
                        </FormControl>

                        <Button
                            type="submit"
                            colorScheme="blue"
                            width="full"
                            mt={8}
                            bg={buttonBgColor}
                            _hover={{ bg: buttonHoverBgColor }}
                        >
                            Register
                        </Button>

                        <Text color="red.500" mt={4}>{errors.firebase}</Text>
                    </form>
</Box>
{isRegistered && !isEmailVerified && (
                <Box mt={8} textAlign="center">
                    <Text>Please verify your email before you can proceed to your dashboard.</Text>
                    <Text>If you have already verified your email, click the button below to check the status.</Text>
                    <Button
                        mt={4}
                        onClick={checkEmailVerification}
                        isLoading={isCheckingVerification}
                        loadingText="Checking..."
                        colorScheme="blue"
                        variant="outline"
                    >
                        Check Verification Status
                    </Button>
                </Box>
            )}

            {isEmailVerified && (
                <Box mt={8} textAlign="center">
                    <Text fontSize="xl" fontWeight="bold" color="green.500">
                        Registration Successful!
                    </Text>
                    <Text mt={4}>
                        Your account has been successfully verified. Redirecting to your dashboard...
                    </Text>
                    <Flex justify="center" mt={8}>
                        <Spacer />
                        <Button
                            colorScheme="green"
                            leftIcon={<CheckIcon />}
                            isLoading={true}
                            loadingText="Redirecting"
                            variant="solid"
                            spinnerPlacement="start"
                        >
                            Success
                        </Button>
                        <Spacer />
                    </Flex>
                </Box>
            )}
        </Box>

        <Modal isOpen={isWeightModalOpen} onClose={onWeightModalClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Competition Weight Information</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>
                        This is the weight you are likely to be at the moment before competing.
                        You will be weighed right before matches start, so pick a weight that you
                        can comfortably walk around at and compete immediately after weighing in.
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme="blue"
                        onClick={() => {
                            setWeightInfoAcknowledged(true);
                            onWeightModalClose();
                        }}
                    >
                        I understand
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </Flex>
);

};

export default RegistrationForm;
