import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase-config';
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Button,
  Select,
  useToast,
  Spinner,
  Center,
  Avatar,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';

function UserProfile() {
  // State variables
  const [userProfile, setUserProfile] = useState(null);
  const [hasMatches, setHasMatches] = useState(false);
  const [loading, setLoading] = useState(true);
  const [gyms, setGyms] = useState([]);
  const [selectedGym, setSelectedGym] = useState('');
  const [showGymPrompt, setShowGymPrompt] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const toast = useToast();

  useEffect(() => {
    if (auth.currentUser) {
      const fetchUserProfile = async () => {
        const userRef = doc(db, 'users', auth.currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserProfile(userSnap.data());
          setSelectedGym(userSnap.data().gym || '');
        } else {
          console.log('No user profile found');
        }
        setLoading(false);
      };

      const fetchGyms = async () => {
        const gymsCollection = collection(db, 'gyms');
        const gymsSnapshot = await getDocs(gymsCollection);
        const gymsList = gymsSnapshot.docs.map(doc => doc.data().name);
        setGyms(gymsList);
      };

      fetchUserProfile();
      fetchGyms();
    } else {
      navigate('/login'); // Redirect to login page if not logged in
    }
  }, [auth.currentUser, navigate]);

  useEffect(() => {
    const checkUserMatches = async () => {
      const matchesQuery = query(
        collection(db, 'matches'),
        where('userIds', 'array-contains', auth.currentUser.uid)
      );
      const matchesSnapshot = await getDocs(matchesQuery);
      setHasMatches(matchesSnapshot.docs.length > 0);
    };

    if (auth.currentUser) {
      checkUserMatches();
    }
  }, [auth.currentUser]);

  const handleGymChange = (e) => {
    setSelectedGym(e.target.value);
  };

  const handleSaveGym = async () => {
    if (auth.currentUser) {
      const confirmChange = window.confirm(`Are you sure you are a member of ${selectedGym}?`);
      if (confirmChange) {
        const userRef = doc(db, 'users', auth.currentUser.uid);
        await updateDoc(userRef, {
          gym: selectedGym,
          gymRequest: {
            gym: selectedGym,
            status: 'pending',
          },
        });
        setShowGymPrompt(false);
        toast({
          title: 'Gym selection submitted for approval!',
          description: 'Your gym selection is pending approval from the gym captain.',
          status: 'info',
          duration: 5000,
          isClosable: true,
        });
        window.location.reload(); // Refresh the page after updating the gym
      }
    }
  };

  if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  // JSX to return
  return (
    <Box p={8} bg="gray.100" minHeight="100vh">
      <Button onClick={() => navigate('/dashboard')} mb={8}>
        Back to Dashboard
      </Button>
      <Center>
        <Box
          maxW="sm"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          boxShadow="lg"
          p={6}
          bg="white"
        >
          <VStack spacing={6} align="center">
            <Avatar size="2xl" name={userProfile?.username} bg="#b9b9b9" />
            <Heading as="h1" size="xl">
              {userProfile?.username || 'Not available'}
            </Heading>
            <Text fontSize="xl">
              {`${userProfile?.firstName || ''} ${userProfile?.lastName || ''}`.trim() || 'Not available'}
            </Text>
            <Text fontSize="md">
              <strong>Email:</strong> {userProfile?.email || 'Not available'}
            </Text>
            <Stat>
                <StatLabel fontSize="md">
                  ELO Rating
                </StatLabel>
                <StatNumber fontSize="2xl">
                  {userProfile?.elo || '0'}
                </StatNumber>
              </Stat>
            <HStack spacing={8}>
              <Stat>
                <StatLabel fontSize="md">
                  Wins
                </StatLabel>
                <StatNumber fontSize="2xl" color="green.500">
                  {Array.isArray(userProfile?.wins) ? userProfile.wins.length : userProfile?.wins || '0'}
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel fontSize="md">
                  Losses
                </StatLabel>
                <StatNumber fontSize="2xl" color="red.500">
                  {Array.isArray(userProfile?.losses) ? userProfile.losses.length : userProfile?.losses || '0'}
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel fontSize="md">
                  Draws
                </StatLabel>
                <StatNumber fontSize="2xl" color="black">
                  {Array.isArray(userProfile?.draws) ? userProfile.draws.length : userProfile?.draws || '0'}
                </StatNumber>
              </Stat>
            </HStack>
            <Text fontSize="md">
              <strong>Gym:</strong>{' '}
              {userProfile?.gymRequest?.status === 'approved'
                ? userProfile?.gym || 'Not available'
                : 'Pending approval'}
            </Text>
            <Button onClick={() => setShowGymPrompt(true)} mt={2} colorScheme="blue">
              Change Gym
            </Button>
            {showGymPrompt && (
              <Box>
                <Select value={selectedGym} onChange={handleGymChange} mb={2}>
                  <option value="">Select a gym</option>
                  {gyms.map((gym) => (
                    <option key={gym} value={gym}>{gym}</option>
                  ))}
                </Select>
                <Button onClick={handleSaveGym} colorScheme="green">
                  Submit for Approval
                </Button>
              </Box>
            )}
          </VStack>
        </Box>
      </Center>
    </Box>
  );
}

export default UserProfile;
