import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import {
  Box,
  VStack,
  Heading,
  Flex,
  useColorModeValue,
  Container,
  Button,
  useBreakpointValue,
  Icon,
} from '@chakra-ui/react';
import { MdCreate, MdPanTool, MdEventNote, MdDelete } from 'react-icons/md';
import { IoIosFitness } from 'react-icons/io';
import { CiCalendarDate, CiViewTable  } from "react-icons/ci";

function AdminDashboard() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const headingColor = useColorModeValue('indigo.600', 'indigo.400');
  const buttonSize = useBreakpointValue({ base: 'md', md: 'lg' });
  const iconSize = useBreakpointValue({ base: 6, md: 8 });

  useEffect(() => {
    setLoading(true);
    const user = auth.currentUser;
    if (!user) {
      navigate('/');
    }
    setLoading(false);
  }, [auth, navigate]);

  if (loading) {
    return <div className="loading">Loading admin dashboard...</div>;
  }

  return (
    <Box bg={bgColor} minHeight="100vh">
      <Container maxW="container.xl" py={8}>
        <VStack spacing={6} align="center">
          <Heading as="h1" size="xl" color={headingColor}>
            Admin Dashboard
          </Heading>
          <Flex direction={{ base: 'column', md: 'row' }} justify="center" align="center" spacing={4} wrap="wrap">
            <Link to="/adminmatches">
              <Button
                size={buttonSize}
                variant="ghost"
                colorScheme="indigo"
                leftIcon={<Icon as={MdCreate} boxSize={iconSize} />}
              >
                Make Matches
              </Button>
            </Link>
            <Link to="/adminpanel">
              <Button
                size={buttonSize}
                variant="ghost"
                colorScheme="indigo"
                leftIcon={<Icon as={MdPanTool} boxSize={iconSize} />}
              >
                Admin Panel
              </Button>
            </Link>
            <Link to="/matchmanager">
              <Button
                size={buttonSize}
                variant="ghost"
                colorScheme="indigo"
                leftIcon={<Icon as={MdEventNote} boxSize={iconSize} />}
              >
                Match Manager
              </Button>
            </Link>
            <Link to="/addtimeslots">
              <Button
                size={buttonSize}
                variant="ghost"
                colorScheme="indigo"
                leftIcon={<Icon as={CiCalendarDate } boxSize={iconSize} />}
              >
                Add Timeslots
              </Button>
            </Link>
            <Link to="/List">
              <Button
                size={buttonSize}
                variant="ghost"
                colorScheme="indigo"
                leftIcon={<Icon as={CiViewTable  } boxSize={iconSize} />}
              >
                User Search
              </Button>
            </Link>
            <Link to="/gyms">
              <Button
                size={buttonSize}
                variant="ghost"
                colorScheme="indigo"
                leftIcon={<Icon as={IoIosFitness} boxSize={iconSize} />}
              >
                Gyms
              </Button>
            </Link>
            <Link to="/TimeSlotDelete">
              <Button
                size={buttonSize}
                variant="ghost"
                colorScheme="indigo"
                leftIcon={<Icon as={MdDelete} boxSize={iconSize} />}
              >
                Delete Timeslots
              </Button>
            </Link>
          </Flex>
        </VStack>
      </Container>
    </Box>
  );
}

export default AdminDashboard;