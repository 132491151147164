import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, or, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import { db } from '../firebase-config';

const MatchHistory = () => {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const fetchMatches = async () => {
      const currentUser = auth.currentUser;
      const effectiveUserId = currentUser ? currentUser.uid : null;

      if (!effectiveUserId) {
        setError('User is not logged in.');
        setLoading(false);
        return;
      }

      try {
        const matchResultsCollection = collection(db, 'matchResults');
        const q = query(
          matchResultsCollection,
          or(
            where('winnerId', '==', effectiveUserId),
            where('loserId', '==', effectiveUserId),
            where('competitorOneId', '==', effectiveUserId),
            where('competitorTwoId', '==', effectiveUserId),
          )
        );
        const querySnapshot = await getDocs(q);
        const matchData = querySnapshot.docs.map(doc => doc.data());

        console.log('Match data:', matchData);

        const userDataSnapshot = await getDoc(doc(db, 'users', effectiveUserId));
        const userData = userDataSnapshot.data();

        const drawMatchData = matchData.filter(match => match.isDraw);
        const nonDrawMatchData = matchData.filter(match => !match.isDraw || match.isDraw == undefined);

        const opponentDetails = await Promise.all(
          nonDrawMatchData.map(async (match) => {
            const opponentId = match.winnerId === effectiveUserId ? match.loserId : match.winnerId;
            const opponentSnapshot = await getDoc(doc(db, 'users', opponentId));
            const opponentData = opponentSnapshot.data();
            const opponentName = opponentData.firstName + " " + opponentData.lastName;
            const userIsWinner = match.winnerId === effectiveUserId;
            const userWeight = userIsWinner ? match.winnerWeight : match.loserWeight;
            const opponentWeight = userIsWinner ? match.loserWeight : match.winnerWeight;
            const result = userIsWinner ? userData.firstName + ' ' + userData.lastName + ' won': opponentName + ' won';

            return {
              id: opponentSnapshot.id,
              user: userData.firstName + " " + userData.lastName,
              opponent: opponentName,
              result,
              submission: match.submission,
              userWeight,
              opponentWeight,
              duration: match.duration,
              date: match.date.toDate().toLocaleString(),
            };
          })
        );

        const drawDetails = await Promise.all(
          drawMatchData.map(async match => {
            const opponentId = match.competitorOneId === effectiveUserId ? match.competitorTwoId : match.competitorOneId;
            const opponentSnapshot = await getDoc(doc(db, 'users', opponentId));
            const opponentData = opponentSnapshot.data();
            const opponentName = opponentData.firstName + " " + opponentData.lastName;
            const userIsWinner = match.winnerId === effectiveUserId;
            const userWeight = userIsWinner ? match.winnerWeight : match.loserWeight;
            const opponentWeight = userIsWinner ? match.loserWeight : match.winnerWeight;
            const result = "Draw";
  
            return {
              id: opponentSnapshot.id,
              user: userData.firstName + " " + userData.lastName,
              opponent: opponentName,
              result,
              submission: match.submission,
              userWeight,
              opponentWeight,
              duration: match.duration,
              date: match.date.toDate().toLocaleString(),
              isDraw: true,
            };
          })
        );

        opponentDetails.push(...drawDetails);

        console.log('Opponent details:', opponentDetails);  
        setMatches(opponentDetails);
      } catch (error) {
        console.error('Error fetching matches:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMatches();
  }, [auth.currentUser]);

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  if (loading) return <Box>Loading...</Box>;
  if (error) return <Box>Error: {error}</Box>;

  return (
    <Box p={4}>
      <Heading as="h2" size="xl" mb={4}>Match History</Heading>
      {matches.length === 0 ? (
        <Text>No matches found.</Text>
      ) : (
        matches.map((match, index) => (
          <Box key={index} p={4} mb={4} borderWidth={1} borderRadius="lg">
            <VStack align="start">
              <Text fontSize="lg"><strong>Match:</strong> {match.user} vs {match.opponent}</Text>
              <Text fontSize="lg"><strong>Result:</strong> {match.result}</Text>
              <Text fontSize="lg"><strong>Submission:</strong> {match.submission || 'None'}</Text>
              <Text fontSize="lg"><strong>{match.user}'s Weight:</strong> {match.userWeight} lbs</Text>
              <Text fontSize="lg"><strong>{match.opponent}'s Weight:</strong> {match.opponentWeight} lbs</Text>
              <Text fontSize="lg"><strong>Match Duration:</strong> {formatDuration(match.duration)}</Text>
              <Text fontSize="lg"><strong>Date:</strong> {match.date}</Text>
            </VStack>
          </Box>
        ))
      )}
    </Box>
  );
};

export default MatchHistory;