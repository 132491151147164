import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BecomeRef.css';

const BecomeRef = () => {
  const navigate = useNavigate();

  // Predefined email content
  const email = "ryan.j.croker@gmail.com";
  const subject = encodeURIComponent("Interest in Becoming a Referee");
  const body = encodeURIComponent("Hi,\n\nI'm interested in becoming a referee. Here's my information:\n\nName: \nEmail: \nMessage: ");

  return (
    <div className="ref-form-container">
      <h2>Become a Referee</h2>
      <p>Currently we are only looking for potential Refs in the Southern California area.  If you have a lot of experiance in grappling and want to inquire about the process, send us a message here.</p>
      
      {/* Mailto link */}
      <a href={`mailto:${email}?subject=${subject}&body=${body}`} className="submit-btn">Email Us to Become a Referee</a>

      {/* Back to Dashboard button */}
      <button className="back-dashboard" onClick={() => navigate('/dashboard')}>Back to Dashboard</button>
    </div>
  );
};

export default BecomeRef;
