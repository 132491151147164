import React, { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs } from 'firebase/firestore';
import {
  Box,
  Text,
  VStack,
  Input,
  Button,
  Select,
  Stack,
  SimpleGrid,
  Heading
} from '@chakra-ui/react';

const beltRanks = ['White', 'Blue', 'Purple', 'Brown', 'Black'];  // Belt ranks ordered

const List = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [minWeight, setMinWeight] = useState('');
  const [maxWeight, setMaxWeight] = useState('');
  const [minBelt, setMinBelt] = useState('');
  const [maxBelt, setMaxBelt] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const snapshot = await getDocs(usersCollection);
      const usersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersData);
      setFilteredUsers(usersData);
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const filtered = users.filter(user => {
      const weight = parseInt(user.weight);
      const beltIndex = beltRanks.indexOf(user.beltRank);
      const minBeltIndex = beltRanks.indexOf(minBelt);
      const maxBeltIndex = beltRanks.indexOf(maxBelt);

      return (
        (!minWeight || weight >= parseInt(minWeight)) &&
        (!maxWeight || weight <= parseInt(maxWeight)) &&
        (minBeltIndex === -1 || beltIndex >= minBeltIndex) &&
        (maxBeltIndex === -1 || beltIndex <= maxBeltIndex)
      );
    });
    setFilteredUsers(filtered);
  }, [minWeight, maxWeight, minBelt, maxBelt, users]);

  return (
    <VStack spacing={5} p={5}>
      <Heading as="h1" size="xl" textAlign="center" mb={5}>
        User List
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
        <Select placeholder="Min Weight" value={minWeight} onChange={(e) => setMinWeight(e.target.value)}>
          {Array.from({ length: (300 - 40) / 10 + 1 }, (_, i) => 40 + i * 10).map(weight => (
            <option key={weight} value={weight}>{weight} lbs</option>
          ))}
        </Select>
        <Select placeholder="Max Weight" value={maxWeight} onChange={(e) => setMaxWeight(e.target.value)}>
          {Array.from({ length: (300 - 40) / 10 + 1 }, (_, i) => 40 + i * 10).map(weight => (
            <option key={weight} value={weight}>{weight} lbs</option>
          ))}
        </Select>
        <Select placeholder="Min Belt" value={minBelt} onChange={(e) => setMinBelt(e.target.value)}>
          {beltRanks.map(belt => (
            <option key={belt} value={belt}>{belt}</option>
          ))}
        </Select>
        <Select placeholder="Max Belt" value={maxBelt} onChange={(e) => setMaxBelt(e.target.value)}>
          {beltRanks.map(belt => (
            <option key={belt} value={belt}>{belt}</option>
          ))}
        </Select>
      </SimpleGrid>
      <Button colorScheme="blue" onClick={() => {
        setMinWeight('');
        setMaxWeight('');
        setMinBelt('');
        setMaxBelt('');
      }}>Clear Filters</Button>
      <VStack spacing={4} divider={<Box border="1px" borderColor="gray.200" w="full" />}>
        {filteredUsers.map((user, index) => (
          <Box key={index} borderWidth="1px" borderRadius="lg" p={4} w="full">
            <Text fontWeight="bold">{user.firstName} {user.lastName}</Text>
            <Text>Weight: {user.weight} lbs</Text>
            <Text>Belt Rank: {user.beltRank}</Text>
          </Box>
        ))}
      </VStack>
    </VStack>
  );
};

export default List;
