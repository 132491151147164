import React, { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { format, parseISO } from 'date-fns';
import {
  Box,
  Heading,
  Text,
  Stack,
  Button,
  Badge,
  Icon,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import {FaCalendarAlt } from 'react-icons/fa';

const UpcomingSchedule = () => {
  const [upcomingReservations, setUpcomingReservations] = useState([]);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [gymNames, setGymNames] = useState({});
  const auth = getAuth();

  // Move the useColorModeValue hook calls here
  const cardBg = useColorModeValue('white', 'gray.700');
  const cardBorder = useColorModeValue('gray.200', 'gray.600');
  // Fetch gym names and store them in state
  useEffect(() => {
    const fetchGyms = async () => {
      const snapshot = await getDocs(collection(db, 'gyms'));
      const gyms = {};
      snapshot.forEach(doc => {
        gyms[doc.id] = doc.data().name;
      });
      setGymNames(gyms);
    };

    fetchGyms();
  }, []);

  useEffect(() => {
    const fetchUpcomingReservations = async () => {
      try {
        const user = auth.currentUser;
        if (!user) return;

        const reservationsQuery = query(
          collection(db, 'reservations'),
          where('userId', '==', user.uid)
        );
        const reservationsSnapshot = await getDocs(reservationsQuery);

        const reservationsData = reservationsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setUpcomingReservations(reservationsData);
      } catch (error) {
        console.error('Error fetching upcoming reservations:', error);
      }
    };

    const fetchUpcomingMatches = async () => {
      try {
        const user = auth.currentUser;
        if (!user) return;

        const matchesRef = collection(db, 'matches');

        // Query for matches where the current user is user1
        const matchesQueryUser1 = query(matchesRef, where('user1Id', '==', user.uid));
        const matchesSnapshotUser1 = await getDocs(matchesQueryUser1);
        const matchesDataUser1 = matchesSnapshotUser1.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Query for matches where the current user is user2
        const matchesQueryUser2 = query(matchesRef, where('user2Id', '==', user.uid));
        const matchesSnapshotUser2 = await getDocs(matchesQueryUser2);
        const matchesDataUser2 = matchesSnapshotUser2.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Combine the data into one array
        const combinedMatchesData = [...matchesDataUser1, ...matchesDataUser2];

        setUpcomingMatches(combinedMatchesData);
      } catch (error) {
        console.error('Error fetching matches:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUpcomingReservations();
    fetchUpcomingMatches();
  }, [gymNames]); 

  const handleDeleteReservation = async (reservationId) => {
    try {
      await deleteDoc(doc(db, 'reservations', reservationId));
      setUpcomingReservations((prevReservations) =>
        prevReservations.filter((reservation) => reservation.id !== reservationId)
      );
      alert('Reservation deleted successfully.');
    } catch (error) {
      console.error('Error deleting reservation:', error);
      alert('Failed to delete reservation.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }


  return (
    <Box p={4}>
      <Flex align="center" mb={8}>
       
        <Heading as="h2" size="xl">
          Upcoming Schedule
        </Heading>
      </Flex>

      {/* Upcoming Matches Section */}
      <Box mb={8}>
        <Flex align="center" mb={4}>
          <Icon as={FaCalendarAlt} boxSize={6} color="blue.500" mr={2} />
          <Heading as="h3" size="lg">
            Upcoming Matches
          </Heading>
        </Flex>
        <Stack spacing={4}>
          {upcomingMatches.map((match) => (
            <Box
              key={match.id}
              p={4}
              bg={cardBg}
              borderWidth={1}
              borderColor={cardBorder}
              borderRadius="md"
              boxShadow="md"
            >
              <Flex align="center" mb={2}>
                <Badge colorScheme="green" mr={2}>
                  Match
                </Badge>
                <Text fontWeight="bold">
                  {format(parseISO(match.date), 'EEEE, MMMM do')} at {match.time}
                </Text>
              </Flex>
              <Text>Gym: {gymNames[match.gymId] || 'Unknown Gym'}</Text>
            </Box>
          ))}
        </Stack>
      </Box>

      {/* Upcoming Reservations Section */}
      <Box>
        <Flex align="center" mb={4}>
          <Icon as={FaCalendarAlt} boxSize={6} color="blue.500" mr={2} />
          <Heading as="h3" size="lg">
            Upcoming Reservations
          </Heading>
        </Flex>
        <Stack spacing={4}>
          {upcomingReservations.map((reservation) => (
            <Box
              key={reservation.id}
              p={4}
              bg={cardBg}
              borderWidth={1}
              borderColor={cardBorder}
              borderRadius="md"
              boxShadow="md"
            >
              <Flex align="center" mb={2}>
                <Badge colorScheme="purple" mr={2}>
                  Reservation
                </Badge>
                <Text fontWeight="bold">
                  {format(parseISO(reservation.date), 'EEEE, MMMM do')} at {reservation.time}
                </Text>
              </Flex>
              <Text>Gym: {gymNames[reservation.gymId] || 'Unknown Gym'}</Text>
              <Button
                colorScheme="red"
                size="sm"
                mt={2}
                onClick={() => handleDeleteReservation(reservation.id)}
              >
                Cancel Slot
              </Button>
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default UpcomingSchedule;