import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Box, Heading, Text, Button, List, ListItem, IconButton } from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { db } from '../firebase-config';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useBreakpointValue } from '@chakra-ui/media-query';

const defaultCenter = { lat: 33.50, lng: -116.35 };

const GymsMap = () => {
  const [gyms, setGyms] = useState([]);
  const [selectedGym, setSelectedGym] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [isTopWindowOpen, setIsTopWindowOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const fetchGyms = async () => {
      if (!db) return; // Ensures db is defined
      const gymsCollection = collection(db, 'gyms');
      try {
        const gymDocs = await getDocs(gymsCollection);
        const gymsData = gymDocs.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
          location: {
            lat: doc.data().latitude,
            lng: doc.data().longitude
          }
        })).filter(gym => gym.location && gym.location.lat && gym.location.lng);

        setGyms(gymsData);
        if (gymsData.length > 0) {
          setMapCenter({
            lat: gymsData[0].location.lat,
            lng: gymsData[0].location.lng
          });
        }
      } catch (e) {
        console.error("Error fetching gyms:", e);
      }
    };

    fetchGyms();
  }, []);

  useEffect(() => {
    if (!selectedGym) return;
    setLoading(true);
    const timeSlotsRef = collection(db, 'timeSlots');
    const q = query(timeSlotsRef, where('gymId', '==', selectedGym.id));

    const fetchTimeSlots = async () => {
      try {
        const querySnapshot = await getDocs(q);
        const slots = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setTimeSlots(slots);
      } catch (error) {
        console.error("Error fetching time slots:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTimeSlots();
  }, [selectedGym]);

  const onSelectGym = gym => {
    setSelectedGym(gym);
    setMapCenter(gym.location);
    setIsTopWindowOpen(true);
  };

  const closeTopWindow = () => {
    setIsTopWindowOpen(false);
    setSelectedGym(null);
    setTimeSlots([]);
  };

  const toggleTopWindow = () => {
    setIsTopWindowOpen(!isTopWindowOpen);
  };

  return (
    <Box height="100vh" width="100vw" position="relative">
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        zoom={10}
        center={mapCenter}
        options={{ disableDefaultUI: true }} // Assuming you want a custom UI
      >
        {gyms.map(gym => (
          <Marker
            key={gym.id}
            position={{ lat: gym.location.lat, lng: gym.location.lng }}
            onClick={() => onSelectGym(gym)}
            // Specify your custom icon if needed
          />
        ))}
      </GoogleMap>

      {(isMobile && isTopWindowOpen) && (
        <IconButton
          icon={isTopWindowOpen ? <CloseIcon /> : <HamburgerIcon />}
          onClick={toggleTopWindow}
          position="absolute"
          top={4}
          left={4}
          zIndex={1}
          colorScheme="blue"
        />
      )}

      {(isTopWindowOpen || !isMobile) && selectedGym && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          p={4}
          bg="white"
          overflowY="auto"
          zIndex={2}
        >
          <Heading size="lg" mb={4}>{selectedGym.name}</Heading>
          <Button onClick={closeTopWindow} mb={4}>
            Close
          </Button>
          <Heading size="md" mb={2}>Available Time Slots:</Heading>
          {loading ? (
            <Text>Loading time slots...</Text>
          ) : timeSlots.length > 0 ? (
            <List spacing={2}>
              {timeSlots.map(slot => (
                <ListItem key={slot.id}>
                  <Text fontWeight="bold">{slot.date}</Text>
                  <Text>{slot.time}</Text>
                </ListItem>
              ))}
            </List>
          ) : (
            <Text>No available time slots.</Text>
          )}
        </Box>
      )}
    </Box>
  );
};

export default GymsMap;
