// ChakraWrapper.js
import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    body: "'Rajdhani', sans-serif",
    heading: "'Rajdhani', sans-serif",
  },
  fontWeights: {
    body: 300,
    heading: 300,
  },
});

const ChakraWrapper = ({ children }) => {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};

export default ChakraWrapper;