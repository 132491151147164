import React, { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs, deleteDoc, doc, writeBatch } from 'firebase/firestore';
import './TimeSlotDelete.css';

const TimeSlotDelete = () => {
  const [timeslots, setTimeslots] = useState({ upcoming: [], past: [], all: [] });

  useEffect(() => {
    const fetchTimeslots = async () => {
      const today = new Date();
      const oneWeekAhead = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
      const upcomingTimeslots = [];
      const pastTimeslots = [];
      const allTimeslots = [];

      const timeslotsSnapshot = await getDocs(collection(db, 'timeSlots'));

      for (const doc of timeslotsSnapshot.docs) {
        const timeslotData = doc.data();
        const timeslotDate = new Date(timeslotData.date);

        const timeslot = {
          id: doc.id,
          ...timeslotData,
          date: timeslotData.date,
        };

        allTimeslots.push(timeslot);

        if (timeslotDate >= today && timeslotDate <= oneWeekAhead) {
          upcomingTimeslots.push(timeslot);
        } else if (timeslotDate < today) {
          pastTimeslots.push(timeslot);
        }
      }

      setTimeslots({ upcoming: upcomingTimeslots, past: pastTimeslots, all: allTimeslots });
    };

    fetchTimeslots();
  }, []);

  const deleteTimeslot = async (timeslotId) => {
    await deleteDoc(doc(db, 'timeSlots', timeslotId));
    setTimeslots({
      upcoming: timeslots.upcoming.filter(ts => ts.id !== timeslotId),
      past: timeslots.past.filter(ts => ts.id !== timeslotId),
      all: timeslots.all.filter(ts => ts.id !== timeslotId),
    });
  };

  const deleteAllTimeslots = async (type) => {
    const batch = writeBatch(db);
    const timeslotsToDelete = timeslots[type];

    timeslotsToDelete.forEach(timeslot => {
      const slotRef = doc(db, 'timeSlots', timeslot.id);
      batch.delete(slotRef);
    });

    await batch.commit();
    setTimeslots({ ...timeslots, [type]: [] });
  };

  return (
    <div className="timeslot-delete">
      <h1>Admin Dashboard</h1>
      <section>
        <h2>All Timeslots</h2>
        <div className="timeslot-list">
          {timeslots.all.map(ts => (
            <div key={ts.id} className="timeslot-item">
              <span>Gym: {ts.gymId}, Timeslot: {ts.date} - {ts.time}</span>
              <button onClick={() => deleteTimeslot(ts.id)} className="delete-button">Delete</button>
            </div>
          ))}
        </div>
      </section>
      <section>
        <h2>Upcoming Timeslots (One Week from Today)</h2>
        <button onClick={() => deleteAllTimeslots('upcoming')} className="delete-all">Delete All Upcoming Timeslots</button>
        <div className="timeslot-list">
          {timeslots.upcoming.map(ts => (
            <div key={ts.id} className="timeslot-item">
              <span>Gym: {ts.gymId}, Timeslot: {ts.date} - {ts.time}</span>
              <button onClick={() => deleteTimeslot(ts.id)} className="delete-button">Delete</button>
            </div>
          ))}
        </div>
      </section>
      <section>
        <h2>Past Timeslots</h2>
        <button onClick={() => deleteAllTimeslots('past')} className="delete-all">Delete All Past Timeslots</button>
        <div className="timeslot-list">
          {timeslots.past.map(ts => (
            <div key={ts.id} className="timeslot-item">
              <span>Gym: {ts.gymId}, Timeslot: {ts.date} - {ts.time}</span>
              <button onClick={() => deleteTimeslot(ts.id)} className="delete-button">Delete</button>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default TimeSlotDelete;