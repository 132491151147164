import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, writeBatch, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase-config'; // Replace with your actual Firebase config
import { Link } from 'react-router-dom';

const AdminMatches = () => {
  const [gyms, setGyms] = useState([]);
  const [selectedGym, setSelectedGym] = useState('');
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState('');
  const [reservations, setReservations] = useState([]);
  const [matches, setMatches] = useState([]);
  const [unmatched, setUnmatched] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Track matched pairs and reset it when needed
  const [matchedPairs, setMatchedPairs] = useState(new Set());

  useEffect(() => {
    setMatchedPairs(new Set()); // Clear matched pairs on gym/slot change
  }, [selectedGym, selectedSlot]);

  // Fetch gyms on component mount
  useEffect(() => {
    const fetchGyms = async () => {
      try {
        const gymsSnapshot = await getDocs(collection(db, 'gyms'));
        setGyms(gymsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (err) {
        setError('Failed to fetch gyms.');
      }
    };
    fetchGyms();
  }, []);

  // Fetch time slots when a gym is selected
  useEffect(() => {
    const fetchTimeSlots = async () => {
      if (!selectedGym) return;
      setLoading(true);
      setError('');
      try {
        const slotsQuery = query(collection(db, 'timeSlots'), where('gymId', '==', selectedGym));
        const snapshot = await getDocs(slotsQuery);
        setTimeSlots(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (err) {
        setError('Failed to fetch time slots.');
      }
      setLoading(false);
    };
    fetchTimeSlots();
  }, [selectedGym]);

  // Fetch reservations when a slot is selected
  useEffect(() => {
    const fetchReservationsForSlot = async () => {
      if (!selectedSlot) return;
      setLoading(true);
      setError('');
      try {
        const resQuery = query(collection(db, 'reservations'), where('slotId', '==', selectedSlot));
        const snapshot = await getDocs(resQuery);
        const reservationsWithUserDetails = await Promise.all(
          snapshot.docs.map(async (resDoc) => {
            const userRef = doc(db, 'users', resDoc.data().userId);
            const userSnap = await getDoc(userRef);
            return { reservationId: resDoc.id, ...resDoc.data(), user: userSnap.data() || null };
          })
        );
        setReservations(reservationsWithUserDetails);
      } catch (err) {
        setError('Failed to fetch reservations.');
      }
      setLoading(false);
    };
    fetchReservationsForSlot();
  }, [selectedSlot]);

  const generateMatches = () => {
    // Start by clearing previous matches
    setMatches([]);
    setUnmatched([]);
  
    try {
      let tempMatches = [];
      let alreadyMatched = new Map(); // Track how many times each user has been matched
  
      // Function to check if a match is valid
      const isValidMatch = (res1, res2) => {
        const weightDiff = Math.abs(res1.user.weight - res2.user.weight);
        const eloDiff = Math.abs(res1.user.elo - res2.user.elo);
        return weightDiff <= 15 && eloDiff <= 500;
      };
  
      reservations.forEach((res1) => {
        if (!alreadyMatched.has(res1.userId)) {
          alreadyMatched.set(res1.userId, []);
        }
  
        // Don't match if user has already been matched 3 times
        if (alreadyMatched.get(res1.userId).length >= 3) return;
  
        for (const res2 of reservations) {
          if (
            res1.userId !== res2.userId && // Not the same user
            isValidMatch(res1, res2) && // Match is valid based on weight and ELO
            !alreadyMatched.get(res1.userId).includes(res2.userId) && // Not already matched with this opponent
            (alreadyMatched.get(res2.userId) ? alreadyMatched.get(res2.userId).length < 3 : true) // Opponent has been matched less than 3 times
          ) {
            // Add match and update already matched users
            tempMatches.push([res1, res2]);
            alreadyMatched.get(res1.userId).push(res2.userId);
  
            if (!alreadyMatched.has(res2.userId)) {
              alreadyMatched.set(res2.userId, []);
            }
            alreadyMatched.get(res2.userId).push(res1.userId);
  
            // Break if user1 has been matched 3 times
            if (alreadyMatched.get(res1.userId).length >= 3) break;
          }
        }
      });
  
      // Filter out the unmatched users
      let unmatchedUsers = reservations.filter(
        (res) => !tempMatches.some((match) => match[0].userId === res.userId || match[1].userId === res.userId)
      );
  
      setMatches(tempMatches);
      setUnmatched(unmatchedUsers);
    } catch (error) {
      console.error('Error generating matches:', error);
      setError(`Error generating matches: ${error.message}`);
    }
  };
  

  const saveMatches = async () => {
    const batch = writeBatch(db);
    matches.forEach(match => {
      const matchRef = doc(collection(db, 'matches'));
      batch.set(matchRef, {
        userId1: match[0].userId,
        userId2: match[1].userId,
        date: match[0].date,
        time: match[0].time,
        gymId: match[0].gymId,
      });
    });
    await batch.commit();
    setError(''); // Clear any previous errors
    setError('Matches saved successfully!'); // Display success message
  };

  return (
    <div>
      <h1>Admin Matches</h1>
      <div>
        <label>
          Select Gym:
          <select value={selectedGym} onChange={(e) => setSelectedGym(e.target.value)}>
            <option value="">Select Gym</option>
            {gyms.map(gym => (
              <option key={gym.id} value={gym.id}>
                {gym.name}
              </option>
            ))}
          </select>
        </label>
      </div>
      {selectedGym && (
        <div>
          <label>
            Select Time Slot:
            <select value={selectedSlot} onChange={(e) => setSelectedSlot(e.target.value)}>
              <option value="">Select Time Slot</option>
              {timeSlots.map(slot => (
                <option key={slot.id} value={slot.id}>
                  {slot.date} - {slot.time}
                </option>
              ))}
            </select>
          </label>
        </div>
      )}
      {selectedSlot && (
        <>
          <h2>Reservations for Selected Time Slot</h2>
          {reservations.length > 0 ? (
      <ul>
        {reservations.map(res => {
          var userGym = gyms.find(g => g.id == res.user.gym);
          return (
            <li key={res.reservationId}>
              {res.user ? `${res.user.firstName} ${res.user.lastName} - Belt: ${res.user.beltRank || 'No belt info'}, Weight: ${res.user.weight || 'N/A'}, ELO: ${res.user.elo || 'N/A'}, Gym: ${userGym ? userGym.name : res.user.gym}` : 'User details not available'}
            </li>
          )
        })}
      </ul>
) : (
  <p>No reservations found for the selected time slot.</p>
)}
          <button onClick={generateMatches} disabled={loading}>
            Generate Matches
          </button>
        </>
      )}
   {matches.length > 0 && (
    <>
      <h2>Generated Matches</h2>
      <ul>
        {matches.map((match, index) => (
          <li key={index}>
            {match[0]?.user?.firstName} vs {match[1]?.user?.firstName}
          </li>
        ))}
      </ul>
      <button onClick={saveMatches} disabled={loading}>
        Save Matches
      </button>
    </>
  )}
  {unmatched.length > 0 && (
    <div>
      <h2>Unmatched Users</h2>
      <ul>
        {unmatched.map((user, index) => (
          <li key={index}>
            {user?.user?.firstName} {user?.user?.lastName}
          </li>
        ))}
      </ul>
    </div>
  )}
      {error && <p>{error}</p>} {/* Display error or success message */}
      <Link to="/dashboard">Back to Dashboard</Link>
    </div>
  );
};

export default AdminMatches;