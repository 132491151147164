import React from 'react';
import {
  Box,
  Heading,
  Text,
  List,
  ListItem,
  ListIcon,
  Divider,
  Badge,
  Code,
  Button
} from '@chakra-ui/react';
import { CheckCircleIcon, StarIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

const EloExplanation = () => {
  const navigate = useNavigate();  // Using useNavigate for navigation

  return (
    <Box p={5} shadow="md" borderWidth="1px" borderRadius="md" bg="gray.50">
      <Heading size="lg" mb={4}>
        Understanding Your Elo Rating
      </Heading>
      <Text fontSize="md" mb={2}>
        The Elo rating is a dynamic score that reflects your performance in matches against other players. Here’s how it works:
      </Text>
      <List spacing={3}>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          <Text as="span" fontWeight="bold">Winning & Losing:</Text> If you win against a higher-ranked opponent, you earn more points. Lose to a lower-ranked opponent, and you'll drop more points.
        </ListItem>
        <ListItem>
          <ListIcon as={StarIcon} color="orange.400" />
          <Text as="span" fontWeight="bold">Weight Classes:</Text> Your weight can influence the points gained or lost after a match. Winning against a heavier opponent earns you extra points.
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          <Text as="span" fontWeight="bold">Submissions:</Text> Securing a win with a submission earns you a bonus, rewarding your skillful victory.
        </ListItem>
      </List>
      <Divider my={4} />
      <Text fontSize="lg" mb={2}>
        Let's see some examples in action:
      </Text>
      
      <Box mb={4}>
        <Heading size="md" mb={2}>Example Matches</Heading>
        <List spacing={3}>
          <ListItem>
            <ListIcon as={ArrowForwardIcon} color="blue.500" />
            <Text as="span" fontWeight="bold">Jack (Elo 1500) vs Alex (Elo 1700):</Text> Jack wins against the odds and gains <Code>20</Code> points, moving up to <Code>1520</Code>. Alex loses <Code>20</Code> points, dropping to <Code>1680</Code>.
          </ListItem>
          <ListItem>
            <ListIcon as={ArrowForwardIcon} color="blue.500" />
            <Text as="span" fontWeight="bold">Mike (Elo 1800, 80kg) vs Joe (Elo 1800, 75kg):</Text> Mike wins, but because he's heavier, he gains <Code>14</Code> points instead of <Code>16</Code>, making his new Elo <Code>1814</Code>. Joe loses <Code>14</Code> points, ending at <Code>1786</Code>.
          </ListItem>
          <ListItem>
            <ListIcon as={ArrowForwardIcon} color="blue.500" />
            <Text as="span" fontWeight="bold">Sam (Elo 1600) submits Bob (Elo 1600):</Text> With the submission, Sam gains an extra <Code>5</Code> points for a total gain of <Code>21</Code>, rising to <Code>1621</Code>. Bob loses <Code>21</Code> points, falling to <Code>1579</Code>.
          </ListItem>
        </List>
      </Box>

      <Box textAlign="center" mt={5}>
        <Button colorScheme="teal" variant="outline" onClick={() => navigate('/dashboard')}>
          Back to Dashboard
        </Button>
      </Box>
    </Box>
  );
};

export default EloExplanation;
