import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import {
  Box,
  VStack,
  Heading,
  Flex,
  useColorModeValue,
  Container,
  Button,
  useBreakpointValue,
  Icon,
} from '@chakra-ui/react';
import { GiBookmarklet, GiCalculator, GiDefensiveWall } from 'react-icons/gi';

function RefDashboard() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const headingColor = useColorModeValue('indigo.600', 'indigo.400');
  const buttonSize = useBreakpointValue({ base: 'md', md: 'lg' });
  const iconSize = useBreakpointValue({ base: 6, md: 8 });

  useEffect(() => {
    setLoading(true);
    const user = auth.currentUser;
    if (!user) {
      navigate('/');
    }
    setLoading(false);
  }, [auth, navigate]);

  if (loading) {
    return <div className="loading">Loading referee dashboard...</div>;
  }

  return (
    <Box bg={bgColor} minHeight="100vh">
      <Container maxW="container.xl" py={8}>
        <VStack spacing={6} align="center">
          <Heading as="h1" size="xl" color={headingColor}>
            Referee Dashboard
          </Heading>
          <Flex direction={{ base: 'column', md: 'row' }} justify="center" align="center" spacing={4}>
            <Link to="/recordmatches">
              <Button
                size={buttonSize}
                variant="ghost"
                colorScheme="indigo"
                leftIcon={<Icon as={GiBookmarklet} boxSize={iconSize} />}
              >
                Finalize Results
              </Button>
            </Link>
            <Link to="/RefereeComponent">
              <Button
                size={buttonSize}
                variant="ghost"
                colorScheme="indigo"
                leftIcon={<Icon as={GiCalculator} boxSize={iconSize} />}
              >
                Match Widget
              </Button>
            </Link>
            <Link to="/waiver">
              <Button
                size={buttonSize}
                variant="ghost"
                colorScheme="indigo"
                leftIcon={<Icon as={GiDefensiveWall} boxSize={iconSize} />}
              >
                Waiver
              </Button>
            </Link>
          </Flex>
        </VStack>
      </Container>
    </Box>
  );
}

export default RefDashboard;