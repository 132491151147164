import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, Text, List, ListItem, Button, VStack } from '@chakra-ui/react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../firebase-config';

function Captain() {
  const [pendingMembers, setPendingMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCaptain, setIsCaptain] = useState(false);
  const [gymId, setGymId] = useState('');
  const auth = getAuth();
  const user = auth.currentUser;
  const navigate = useNavigate();

  useEffect(() => {
    const checkCaptainStatus = async () => {
      if (!user) return;
      const gymsRef = collection(db, 'gyms');
      const q = query(gymsRef, where('captain', '==', user.uid));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        setIsCaptain(true);
        const gymId = querySnapshot.docs[0].id;
        setGymId(gymId);
        fetchPendingMembers(gymId, querySnapshot.docs[0].data().name);
      } else {
        setIsCaptain(false);
      }
      setIsLoading(false);
    };

    checkCaptainStatus();
  }, [user]);

  const fetchPendingMembers = async (gymId, gymName) => {
    const usersRef = collection(db, 'users');
    const q = query(
      usersRef,
      where('gymRequest.gym', 'in', [gymId, gymName]),
      where('gymRequest.status', '==', 'pending')
    );

    const querySnapshot = await getDocs(q);
    const members = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setPendingMembers(members);
  };

  const handleApprove = async (userId) => {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, { 'gymRequest.status': 'approved' });
    setPendingMembers((prevMembers) => prevMembers.filter((member) => member.id !== userId));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isCaptain) {
    return <div>You are not a captain of any gym.</div>;
  }

  return (
    <Box p={4}>
      <VStack spacing={4} align="stretch">
        <Heading as="h2" size="xl">
          Captain's Dashboard
        </Heading>
        <Box>
          <Heading as="h3" size="lg" mb={2}>
            Pending Members:
          </Heading>
          {pendingMembers.length > 0 ? (
            <List spacing={3}>
              {pendingMembers.map((member) => (
                <ListItem key={member.id}>
                  {member.username} - {member.firstName} {member.lastName}
                  <Button size="sm" colorScheme="green" ml={4} onClick={() => handleApprove(member.id)}>
                    Approve
                  </Button>
                </ListItem>
              ))}
            </List>
          ) : (
            <Text>No pending members.</Text>
          )}
        </Box>
      </VStack>
    </Box>
  );
}

export default Captain;

