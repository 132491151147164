import React, { useState, useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
  VStack,
  Text,
} from '@chakra-ui/react';
import { db } from '../firebase-config';
import { collection, getDocs } from 'firebase/firestore';

function Matches() {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState({});

  useEffect(() => {
    const fetchMatches = async () => {
      const matchesCollection = collection(db, 'matches');
      const matchesSnapshot = await getDocs(matchesCollection);
      const matchesData = matchesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMatches(matchesData);
    };

    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersData = usersSnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data();
        return acc;
      }, {});
      setUsers(usersData);
    };

    Promise.all([fetchMatches(), fetchUsers()]).then(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <VStack mt={8}>
        <Spinner size="xl" />
        <Text>Loading Matches...</Text>
      </VStack>
    );
  }

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Match ID</Th>
            <Th>Competitor 1</Th>
            <Th>Competitor 2</Th>
            <Th>Weight 1</Th>
            <Th>Weight 2</Th>
            <Th>Points 1</Th>
            <Th>Points 2</Th>
            <Th>Submission</Th>
            <Th>Duration</Th>
          </Tr>
        </Thead>
        <Tbody>
          {matches.map(match => (
            <Tr key={match.id}>
              <Td>{match.id}</Td>
              <Td>{users[match.competitorOne]?.firstName} {users[match.competitorOne]?.lastName}</Td>
              <Td>{users[match.competitorTwo]?.firstName} {users[match.competitorTwo]?.lastName}</Td>
              <Td>{match.weightOne}</Td>
              <Td>{match.weightTwo}</Td>
              <Td>{match.pointsOne}</Td>
              <Td>{match.pointsTwo}</Td>
              <Td>{match.submission}</Td>
              <Td>{match.duration}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default Matches;
