import React, { useState, useEffect, useRef } from 'react';
import SignaturePad from 'react-signature-canvas';
import { getFirestore, collection, getDocs, doc, setDoc } from 'firebase/firestore';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  Select,
  useToast,
} from '@chakra-ui/react';

import './Waiver.css'; // Ensure this CSS file is created

const Waiver = () => {
  const [selectedParticipant, setSelectedParticipant] = useState('');
  const [participants, setParticipants] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const sigCanvas = useRef({});
  const firestore = getFirestore();
  const toast = useToast();

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        const participantsList = [];
        const querySnapshot = await getDocs(collection(firestore, 'users'));
        querySnapshot.forEach((doc) => {
          participantsList.push({ id: doc.id, name: `${doc.data().firstName} ${doc.data().lastName}` });
        });
        participantsList.sort((a, b) => a.name.localeCompare(b.name)); // Sort alphabetically
        setParticipants(participantsList);
      } catch (error) {
        console.error('Error fetching participants:', error);
        toast({
          title: 'Error fetching participants',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    };

    fetchParticipants();
  }, [firestore, toast]);

  const saveSignature = async () => {
    if (!selectedParticipant) {
      toast({
        title: 'Error',
        description: 'Please select a participant.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const signatureData = sigCanvas.current.toDataURL('image/png');
      const signatureDocRef = doc(firestore, 'signatures', selectedParticipant);

      await setDoc(signatureDocRef, {
        signatureData,
        timestamp: new Date().toISOString(),
      });

      toast({
        title: 'Signature saved',
        description: 'The signature has been saved successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving signature:', error);
      toast({
        title: 'Error saving signature',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const waiverText = `
    I hereby assume all of the risks of participating in any/all activities associated with this event, 
    including by way of example and not limitation, any risks that may arise from negligence or carelessness 
    on the part of the persons or entities being released, from dangerous or defective equipment or property 
    owned, maintained, or controlled by them, or because of their possible liability without fault.

    I certify that I am physically fit, have sufficiently trained for participation in this event, and have 
    not been advised otherwise by a qualified medical person.

    I acknowledge that this Accident Waiver and Release of Liability Form will be used by the event holders, 
    sponsors, and organizers of the activity in which I may participate, and that it will govern my actions 
    and responsibilities at said activity.

    In consideration of my application and permitting me to participate in this event, I hereby take action 
    for myself, my executors, administrators, heirs, next of kin, successors, and assigns as follows: 
    (A) I WAIVE, RELEASE, AND DISCHARGE from any and all liability, including but not limited to, liability 
    arising from the negligence or fault of the entities or persons released, for my death, disability, 
    personal injury, property damage, property theft, or actions of any kind which may hereafter occur to me 
    including my traveling to and from this event, THE FOLLOWING ENTITIES OR PERSONS: The event holders, 
    sponsors, and organizers;

    (B) I INDEMNIFY, HOLD HARMLESS, AND PROMISE NOT TO SUE the entities or persons mentioned in this paragraph 
    from any and all liabilities or claims made as a result of participation in this event, whether caused 
    by the negligence of release or otherwise.
  `;

  return (
    <Box className="waiver-container" p={4}>
      <FormControl id="participant" mb={4}>
        <FormLabel>Search Participant</FormLabel>
        <Input
          placeholder="Type to search..."
          onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
        />
        <Select
          mt={2}
          placeholder="Select participant"
          onChange={(e) => setSelectedParticipant(e.target.value)}
        >
          {participants
            .filter((participant) =>
              participant.name.toLowerCase().includes(searchTerm)
            )
            .map((participant) => (
              <option key={participant.id} value={participant.id}>
                {participant.name}
              </option>
            ))}
        </Select>
      </FormControl>

      <Box mb={4}>
        <FormLabel>Waiver Text</FormLabel>
        <Textarea value={waiverText} readOnly h="200px" />
      </Box>

      <Box mb={4}>
        <FormLabel>Signature</FormLabel>
        <Box border="1px" borderColor="gray.300" p={2}>
          <SignaturePad
            ref={sigCanvas}
            penColor="black"
            canvasProps={{ width: 300, height: 150, className: 'sigCanvas' }}
          />
        </Box>
      </Box>

      <VStack spacing={4} align="stretch">
        <Button colorScheme="blue" onClick={saveSignature}>
          Save Signature
        </Button>
        <Button colorScheme="red" onClick={clearSignature}>
          Clear Signature
        </Button>
      </VStack>
    </Box>
  );
};

export default Waiver;

