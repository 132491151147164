import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebase-config';
import { collection, doc, getDoc, getDocs, runTransaction, addDoc } from 'firebase/firestore';
import './RecordMatches.css';

const MatchHistory = () => {
  const [matches, setMatches] = useState([]);
  const [selectedWinners, setSelectedWinners] = useState({});
  const [matchWeights, setMatchWeights] = useState({});
  const [youtubeVideoIds, setYoutubeVideoIds] = useState({});
  const [matchDurations, setMatchDurations] = useState({});
  const [points, setPoints] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const matchesSnapshot = await getDocs(collection(db, 'matches'));
        const matchesData = await Promise.all(
          matchesSnapshot.docs.map(async (docSnapshot) => {
            const matchData = docSnapshot.data();
            console.log('Match data:', matchData);
  
            if (!matchData.competitorOne || !matchData.competitorTwo) {
              console.error('One of the competitor IDs is missing.');
              return null;
            }
  
            try {
              console.log(`Fetching data for competitorOne: ${matchData.competitorOne}, competitorTwo: ${matchData.competitorTwo}`);
              const competitorOneRef = doc(db, 'users', matchData.competitorOne);
              const competitorTwoRef = doc(db, 'users', matchData.competitorTwo);
  
              const competitorOneDoc = await getDoc(competitorOneRef);
              const competitorTwoDoc = await getDoc(competitorTwoRef);
  
              if (!competitorOneDoc.exists()) {
                console.error(`CompetitorOne document does not exist: ${matchData.competitorOne}`);
              }
              if (!competitorTwoDoc.exists()) {
                console.error(`CompetitorTwo document does not exist: ${matchData.competitorTwo}`);
              }
  
              if (!competitorOneDoc.exists() || !competitorTwoDoc.exists()) {
                return null;
              }
  
              return {
                id: docSnapshot.id,
                ...matchData,
                competitorOneData: competitorOneDoc.data(),
                competitorTwoData: competitorTwoDoc.data(),
              };
            } catch (err) {
              console.error('Error fetching competitor data:', err);
              return null;
            }
          })
        );
  
        const filteredMatches = matchesData.filter((match) => {
          if (!match || !match.date) return false;
          return selectedDate ? match.date === selectedDate : true;
        });
  
        setMatches(filteredMatches);
  
        const initialMatchWeights = filteredMatches.reduce((weights, match) => {
          weights[match.id] = {
            weightOne: match.weightOne || '',
            weightTwo: match.weightTwo || '',
            submission: match.submission || '',
            submissionChecked: match.submission !== null && match.submission != 'None',
          };
          return weights;
        }, {});
        setMatchWeights(initialMatchWeights);
  
        const initialYoutubeVideoIds = filteredMatches.reduce((videoIds, match) => {
          videoIds[match.id] = match.youtubeVideoId || '';
          return videoIds;
        }, {});
        setYoutubeVideoIds(initialYoutubeVideoIds);
  
        const initialMatchDurations = filteredMatches.reduce((durations, match) => {
          durations[match.id] = match.duration || '';
          return durations;
        }, {});
        setMatchDurations(initialMatchDurations);
  
        const initialPoints = filteredMatches.reduce((points, match) => {
          points[match.id] = {
            pointsOne: match.pointsOne || 0,
            pointsTwo: match.pointsTwo || 0,
          };
          return points;
        }, {});
        setPoints(initialPoints);
  
        const initialSelectedWinners = filteredMatches.reduce((winners, match) => {
          if (match.winner) {
            winners[match.id] = match.winner;
          } else if (match.pointsOne > match.pointsTwo) {
            winners[match.id] = match.competitorOne;
          } else if (match.pointsTwo > match.pointsOne) {
            winners[match.id] = match.competitorTwo;
          }
          return winners;
        }, {});
        console.log('Initial selected winners:', initialSelectedWinners);
        setSelectedWinners(initialSelectedWinners);
  
        setLoading(false);
      } catch (error) {
        console.error('Error fetching match data:', error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, [selectedDate]);
  
  
  
  

  const handleWeightChange = (matchId, competitor, weight) => {
    setMatchWeights((prevWeights) => ({
      ...prevWeights,
      [matchId]: { ...prevWeights[matchId], [competitor]: weight },
    }));
  };

  const handlePointsChange = (matchId, competitor, points) => {
    setPoints((prevPoints) => ({
      ...prevPoints,
      [matchId]: { ...prevPoints[matchId], [competitor]: points },
    }));
  };
  
  const handleDurationChange = (matchId, duration) => {
    setMatchDurations((prevDurations) => ({
      ...prevDurations,
      [matchId]: duration,
    }));
  };

  const handleWinnerSelect = (matchId, winnerId) => {
        setSelectedWinners((prevState) => ({
      ...prevState,
      [matchId]: winnerId,
    }));
  };
  

  const calculateNewElo = (winnerElo, loserElo, winnerWeight, loserWeight, submission) => {
    const kFactor = 32;
    const eloDifference = loserElo - winnerElo;
    const winnerExpected = 1 / (1 + Math.pow(10, eloDifference / 400));
    const loserExpected = 1 - winnerExpected;

    let winnerEloChange = Math.round(kFactor * (1 - winnerExpected));
    let loserEloChange = Math.round(kFactor * (loserExpected - 1));

    const weightDifference = winnerWeight - loserWeight;
    const weightAdjustmentFactor = 1 + Math.abs(weightDifference) / 1000;

    if (winnerWeight < loserWeight) {
      winnerEloChange = Math.round(winnerEloChange * weightAdjustmentFactor);
    } else {
      loserEloChange = Math.round(loserEloChange * weightAdjustmentFactor);
    }

    if (submission) {
      winnerEloChange += 5;
      loserEloChange -= 5;
    }

    const winnerNewElo = Math.max(winnerElo + winnerEloChange, 0);
    const loserNewElo = Math.max(loserElo + loserEloChange, 0);

    return { winnerNewElo, loserNewElo };
  };

  const recordResult = async (matchId, winnerId, loserId, winnerWeight, loserWeight, submissionChecked) => {
    const matchRef = doc(db, 'matches', matchId);
      
    try {
      const matchDoc = await getDoc(matchRef);
      if (!matchDoc.exists()) {
        console.error(`Match document does not exist: ${matchId}`);
        return;
      }
  
      const matchData = matchDoc.data();

      const competitorOneId = matchData.competitorOne;
      const competitorTwoId = matchData.competitorTwo;
      const isDraw = matchData.pointsOne === matchData.pointsTwo && submissionChecked === false;

      console.log('Match data:', matchData);
      console.log('Is draw:', isDraw);

      if (isDraw) {
        await runTransaction(db, async (transaction) => {
          const competitorOneRef = doc(db, 'users', competitorOneId);
          const competitorTwoRef = doc(db, 'users', competitorTwoId);
    
          const competitorOneDoc = await transaction.get(competitorOneRef);
          const competitorTwoDoc = await transaction.get(competitorTwoRef);
    
          if (!competitorOneDoc.exists()) {
            console.error(`CompetitorOne document does not exist: ${competitorOneId}`);
            return;
          }
          if (!competitorTwoDoc.exists()) {
            console.error(`CompetitorTwo document does not exist: ${competitorTwoId}`);
            return;
          }
    
          const competitorOneData = competitorOneDoc.data();
          const competitorTwoData = competitorTwoDoc.data();
  
          transaction.update(competitorOneRef, {
            draws: [...(competitorOneData.draws || []), { matchId, opponentId: competitorTwoId, date: new Date() }],
          });
    
          transaction.update(competitorTwoRef, {
            draws: [...(competitorTwoData.draws || []), { matchId, opponentId: competitorOneId, date: new Date() }],
          });
    
          const matchData = {
            winnerId: competitorOneId,
            loserId: competitorTwoId,
            competitorOneId: competitorOneId,
            competitorTwoId: competitorTwoId,
            winnerWeight,
            loserWeight,
            submission: "None",
            youtubeVideoId: youtubeVideoIds[matchId] || '',
            duration: matchDurations[matchId] || 0,
            pointsOne: points[matchId]?.pointsOne || 0,
            pointsTwo: points[matchId]?.pointsTwo || 0,
            date: new Date(),
            isDraw: true,
          };
    
          transaction.update(matchRef, matchData);

          // Save match result permanently
          const matchResultsCollection = collection(db, 'matchResults');
          await addDoc(matchResultsCollection, matchData);
    
          alert('Match result recorded successfully!');
        })
      }
      else {
        // Map the winner and loser IDs to the actual user IDs
        const actualWinnerId = winnerId === 'competitorOne' ? competitorOneId : competitorTwoId;
        const actualLoserId = loserId === 'competitorTwo' ? competitorTwoId : competitorOneId;
    
        const winnerRef = doc(db, 'users', actualWinnerId);
        const loserRef = doc(db, 'users', actualLoserId);

        
    
        await runTransaction(db, async (transaction) => {
          const winnerDoc = await transaction.get(winnerRef);
          const loserDoc = await transaction.get(loserRef);
    
          if (!winnerDoc.exists()) {
            console.error(`Winner document does not exist: ${actualWinnerId}`);
            return;
          }
          if (!loserDoc.exists()) {
            console.error(`Loser document does not exist: ${actualLoserId}`);
            return;
          }
    
          const winnerData = winnerDoc.data();
          const loserData = loserDoc.data();
    
          const winnerElo = Number(winnerData.elo);
          const loserElo = Number(loserData.elo);
    
          if (isNaN(winnerElo) || isNaN(loserElo)) {
            throw new Error(`Invalid Elo scores: ${winnerElo}, ${loserElo}`);
          }
    
          const submission = matchWeights[matchId]?.submission;
    
          // Parse the weight values
          const parsedWinnerWeight = parseFloat(winnerWeight);
          const parsedLoserWeight = parseFloat(loserWeight);
    
          // Check if the parsed weight values are valid numbers
          if (isNaN(parsedWinnerWeight) || isNaN(parsedLoserWeight)) {
            throw new Error('Invalid weight values');
          }
    
          const { winnerNewElo, loserNewElo } = calculateNewElo(
            winnerElo,
            loserElo,
            parsedWinnerWeight,
            parsedLoserWeight,
            submission
          );
    
          transaction.update(winnerRef, {
          elo: winnerNewElo,
          wins: [...(winnerData.wins || []), { matchId, opponentId: actualLoserId, result: 'win', date: new Date() }],
          });
    
          transaction.update(loserRef, {
          elo: loserNewElo,
          losses: [...(loserData.losses || []), { matchId, opponentId: actualWinnerId, result: 'loss', date: new Date() }],
          });
    
          const matchData = {
            winnerId: actualWinnerId,
            loserId: actualLoserId,
            winnerWeight: parsedWinnerWeight,
            loserWeight: parsedLoserWeight,
            submission: submissionChecked ? submission : 'None',
            youtubeVideoId: youtubeVideoIds[matchId] || '',
            duration: matchDurations[matchId] || 0,
            pointsOne: points[matchId]?.pointsOne || 0,
            pointsTwo: points[matchId]?.pointsTwo || 0,
            date: new Date(),
          };

          transaction.update(matchRef, matchData);
    
          // Save match result permanently
          const matchResultsCollection = collection(db, 'matchResults');
          await addDoc(matchResultsCollection, matchData);
    
          alert('Match result recorded successfully!');
        });
      }
  
      console.log('Match result recorded successfully');
    } catch (error) {
      console.error('Error recording match result:', error);
    }
  };

  const formatDate = (date) => {
    if (!date || !date.seconds) return 'Invalid Date';
    const dateObj = new Date(date.seconds * 1000);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return dateObj.toLocaleDateString(undefined, options);
  };

  return (
    <div className="match-history">
      <h2>Match History</h2>
      <div className="date-picker">
        <label>
          Select Date:
          <input
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          />
        </label>
      </div>
      {loading ? (
        <div>Loading matches...</div>
      ) : matches.length === 0 ? (
        <div>No matches found for the selected date</div>
      ) : (
        matches.map((match) => (
          <div key={match.id} className="match">
            <div className="match-details">Match: {formatDate(match.date)}</div>
            <div className="weight-input">
              <label>
                {match.competitorOneData?.firstName} {match.competitorOneData?.lastName} Weight:
                <input
                  type="number"
                  placeholder="Enter weight"
                  value={matchWeights[match.id]?.weightOne || ''}
                  onChange={(e) => handleWeightChange(match.id, 'weightOne', e.target.value)}
                />
              </label>
              <label>
                {match.competitorTwoData?.firstName} {match.competitorTwoData?.lastName} Weight:
                <input
                  type="number"
                  placeholder="Enter weight"
                  value={matchWeights[match.id]?.weightTwo || ''}
                  onChange={(e) => handleWeightChange(match.id, 'weightTwo', e.target.value)}
                />
              </label>
            </div>
            <div className="points-input">
              <label>
                {match.competitorOneData?.firstName} {match.competitorOneData?.lastName} Points:
                <input
                  type="number"
                  placeholder="Enter points"
                  value={points[match.id]?.pointsOne || 0}
                  onChange={(e) => handlePointsChange(match.id, 'pointsOne', e.target.value)}
                />
              </label>
              <label>
                {match.competitorTwoData?.firstName} {match.competitorTwoData?.lastName} Points:
                <input
                  type="number"
                  placeholder="Enter points"
                  value={points[match.id]?.pointsTwo || 0}
                  onChange={(e) => handlePointsChange(match.id, 'pointsTwo', e.target.value)}
                />
              </label>
            </div>
            <div className="duration-input">
              <label>
                Match Duration (seconds):
                <input
                  type="number"
                  placeholder="Enter match duration"
                  value={matchDurations[match.id] || ''}
                  onChange={(e) => handleDurationChange(match.id, e.target.value)}
                />
              </label>
            </div>
            <div className="youtube-input">
              <label>
                YouTube Video ID:
                <input
                  type="text"
                  placeholder="Enter YouTube video ID"
                  value={youtubeVideoIds[match.id] || ''}
                  onChange={(e) =>
                    setYoutubeVideoIds((prevState) => ({
                      ...prevState,
                      [match.id]: e.target.value,
                    }))
                  }
                />
              </label>
            </div>
            <div className="winner-select">
  <label>
    <input
      type="radio"
      name={`winner-${match.id}`}
      value="competitorOne"
      checked={selectedWinners[match.id] === 'competitorOne'}
      onChange={() => handleWinnerSelect(match.id, 'competitorOne')}
    />
    {match.competitorOneData?.firstName} {match.competitorOneData?.lastName}
  </label>
  <label>
    Submission:
    <input
      type="checkbox"
      checked={matchWeights[match.id]?.submissionChecked}
      onChange={(e) =>
        setMatchWeights((prevWeights) => ({
          ...prevWeights,
          [match.id]: { ...prevWeights[match.id], submissionChecked: e.target.checked },
        }))
      }
    />
    {matchWeights[match.id]?.submission && (
      <div>Submission Type: {matchWeights[match.id]?.submission}</div>
    )}
  </label>
  <label>
    <input
      type="radio"
      name={`winner-${match.id}`}
      value="competitorTwo"
      checked={selectedWinners[match.id] === 'competitorTwo'}
      onChange={() => handleWinnerSelect(match.id, 'competitorTwo')}
    />
    {match.competitorTwoData?.firstName} {match.competitorTwoData?.lastName}
  </label>
</div>

            <button
              className="record-result-button"
              onClick={() => {
                const winnerId = selectedWinners[match.id];
                const loserId = winnerId === "competitorOne" ? "competitorTwo" : "competitorOne";
                const weightOne = matchWeights[match.id]?.weightOne !== undefined ? matchWeights[match.id].weightOne : match.weightOne;
                const weightTwo = matchWeights[match.id]?.weightTwo !== undefined ? matchWeights[match.id].weightTwo : match.weightTwo;

                if (weightOne && weightTwo && !isNaN(weightOne) && !isNaN(weightTwo)) {
                  recordResult(match.id, winnerId, loserId, weightOne, weightTwo, matchWeights[match.id]?.submissionChecked);
                } else {
                  console.error('Weights must be valid numbers for both competitors.');
                }
              }}
              disabled={!selectedWinners[match.id] || !matchWeights[match.id]}
            >
              Record Result
            </button>
          </div>
        ))
      )}

      <Link to="/dashboard" className="back-dashboard-button">
        Back to Dashboard
      </Link>
    </div>
  );
};

export default MatchHistory;
