import React, { useState } from 'react';
import { db } from '../firebase-config';
import { collection, query, getDocs } from 'firebase/firestore';
import {
  Box,
  Text,
  Heading,
  VStack,
  StackDivider,
  Input,
  useBreakpointValue,
  Button,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const UserLookup = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [matchHistory, setMatchHistory] = useState([]);
  const navigate = useNavigate();

  const handleSearch = async (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef);
      const querySnapshot = await getDocs(q);
      const usersData = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(user =>
          (user.username && user.username.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (user.firstName && user.firstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (user.lastName && user.lastName.toLowerCase().includes(searchTerm.toLowerCase()))
        );
      setUsers(usersData);
    } catch (error) {
      console.error('Error searching for users:', error);
    }
  };
  

  const handleSelectUser = async (user) => {
    setSelectedUser(user);
    setSearchTerm('');
    setUsers([]);

    try {
      const matchHistoryRef = collection(db, 'users', user.id, 'matchHistory');
      const matchHistorySnapshot = await getDocs(matchHistoryRef);
      const matchHistoryData = matchHistorySnapshot.docs.map(doc => doc.data());
      setMatchHistory(matchHistoryData);
    } catch (error) {
      console.error('Error fetching match history:', error);
    }
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleReturnToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <Box maxWidth="600px" mx="auto" px={4}>
      <Heading as="h1" size="xl" mb={8} textAlign="center">
        User Lookup
      </Heading>
      <Text mb={4}>
        Search for a user by their username, first name, or last name to view their profile and match history.
      </Text>
      <Input
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Search users..."
        size="lg"
        variant="filled"
        mb={4}
      />
      {users.length > 0 && (
        <Box mb={4}>
          {users.map((user, index) => (
            <Box
              key={index}
              onClick={() => handleSelectUser(user)}
              cursor="pointer"
              p={2}
              borderWidth={1}
              borderRadius="md"
              mb={2}
            >
              {user.firstName} {user.lastName} ({user.username})
            </Box>
          ))}
        </Box>
      )}

      {selectedUser && (
        <VStack spacing={6} align="stretch" divider={<StackDivider />}>
          <Box>
            <Heading as="h2" size={isMobile ? 'lg' : 'xl'} mb={4} textAlign="center">
              {selectedUser.firstName} {selectedUser.lastName}
            </Heading>
            <Text fontSize={isMobile ? 'md' : 'lg'} mb={2}>
              Username: {selectedUser.username}
            </Text>
          
            <Text fontSize={isMobile ? 'md' : 'lg'} mb={2}>
              Region: {selectedUser.region}
            </Text>
          </Box>

          <Box>
            <Heading as="h3" size={isMobile ? 'md' : 'lg'} mb={4} textAlign="center">
              Match History
            </Heading>
            {matchHistory.map((match, index) => (
              <Box key={index} mb={4}>
                <Text>
                  Date: {new Date(match.date).toLocaleString()}
                </Text>
                <Text>
                  Opponent: {match.opponentId}
                </Text>
                <Text fontWeight="bold">
                  Result: {match.result}
                </Text>
              </Box>
            ))}
          </Box>
        </VStack>
      )}

      <Box mt={8} textAlign="center">
        <Button onClick={handleReturnToDashboard} colorScheme="blue">
          Return to Dashboard
        </Button>
      </Box>
    </Box>
  );
};

export default UserLookup;