import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import {
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Link,
} from '@chakra-ui/react';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');

    if (!isResettingPassword) {
      // Attempt to sign in
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        navigate('/dashboard', { state: { userId: userCredential.user.uid } });
      } catch (error) {
        setError('Failed to log in: ' + error.message);
      }
    } else {
      // Attempt to send a password reset email
      try {
        await sendPasswordResetEmail(auth, email);
        setError("Password reset email sent. Check your inbox.");
        setIsResettingPassword(false);
      } catch (error) {
        setError("Failed to send password reset email: " + error.message);
      }
    }
  };
  
  
    
    return (
      <Box minHeight="100vh" width="100%" bg="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={4}>
        {/* Lines to the right */}
        <Box
          display={{ base: 'none', md: 'block' }}
          position="fixed"
          top="0"
          right="0"
          height="100%"
          width="60px"
          sx={{ zIndex: '1' }}
        >
          <Box height="100%" width="3px" bgColor="black" position="absolute" left="7px" />
          <Box height="100%" width="8px" bgColor="black" position="absolute" left="13px" />
          <Box height="100%" width="9px" bgColor="red.500" position="absolute" left="26px" />
          <Box height="100%" width="20px" bgColor="black" position="absolute" left="37px" />
        </Box>
  
        {/* Login Box */}
        <Box
          sx={{
            background: 'transparent',
            padding: '1rem',
            borderRadius: '4px',
            maxWidth: '400px',
            width: '100%',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: '2',
          }}
        >
          {/* PGR Heading */}
          <VStack spacing={1} align="center" marginBottom="1rem">
            <Text fontSize="3xl" fontWeight="bold">PGR</Text>
            <Text fontSize="xs">So Cal Jiu Jitsu League</Text>
          </VStack>
  
          {/* Login Form */}
          <VStack as="form" spacing={3} align="stretch" onSubmit={handleLogin}>
            <Text fontSize="xl" sx={{ color: '#1a1a1a', mb: 2 }}>Login</Text>
            {error && <Text sx={{ color: 'red.500' }}>{error}</Text>}
            <FormControl isRequired>
              <FormLabel fontSize="xs">Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ border: '1px solid #000', borderRadius: '4px', padding: '0.5rem', fontSize: '0.875rem', color: '#333', backgroundColor: '#fff', marginBottom: '0.5rem' }}
              />
            </FormControl>
            {!isResettingPassword && (
              <FormControl isRequired>
                <FormLabel fontSize="xs">Password</FormLabel>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{ border: '1px solid #000', borderRadius: '4px', padding: '0.5rem', fontSize: '0.875rem', color: '#333', backgroundColor: '#fff', marginBottom: '0.5rem' }}
                />
              </FormControl>
            )}
            {!isResettingPassword && (
              <Button type="submit" sx={{ backgroundColor: '#ff0000', color: 'white', borderRadius: '4px', padding: '0.75rem', fontSize: '0.875rem', width: '100%' }} _hover={{ bg: '#3d0a0a' }}>
                Login
              </Button>
            )}
            {isResettingPassword && (
              <Button type="submit" sx={{ backgroundColor: '#ff0000', color: 'white', borderRadius: '4px', padding: '0.75rem', fontSize: '0.875rem', width: '100%' }} _hover={{ bg: '#3d0a0a' }}>
                Send Reset Email
              </Button>
            )}
            {!isResettingPassword && (
              <Button variant="link" onClick={() => setIsResettingPassword(true)} sx={{ color: '#ff0000', textAlign: 'center', width: '100%', padding: '0.25rem', fontSize: '0.875rem' }} _hover={{ textDecoration: 'underline' }}>
                Forgot Password?
              </Button>
            )}
            {isResettingPassword && (
              <Button variant="link" onClick={() => setIsResettingPassword(false)} sx={{ color: '#ff0000', textAlign: 'center', width: '100%', padding: '0.25rem', fontSize: '0.875rem' }} _hover={{ textDecoration: 'underline' }}>
                Back to Login
              </Button>
            )}
            {!isResettingPassword && (
              <Text sx={{ textAlign: 'center', fontSize: '0.875rem' }}>
                Don't have an account? <Link as={RouterLink} to="/registrationform" sx={{ color: 'teal.500' }}>
                  Register here
                </Link>
              </Text>
            )}
          </VStack>
        </Box>
      </Box>
    );
  }

export default Login;