// Support.js
import React from 'react';

function Support() {
  return (
    <div>
      <h1>Support</h1>
      <p>If you need help, you can reach us at...</p>
    </div>
  );
}

export default Support;
