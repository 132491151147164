import React, { useEffect, useState } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './AdminPanel.css';

const AdminPanel = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [gyms, setGyms] = useState([]);
  const [selectedGym, setSelectedGym] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const adminUids = ['ECJqIXCF23aJYRep95gbwyr6FI32','PAxEPLHUl8OtMJU8RN3ZgdQ1GIQ2', 'cFcO38roPPR7zlFOBFnve9mqOB73', 'M7wImlJrw2SIsxYgzp6WJmLVLX33'];
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && adminUids.includes(user.uid)) {
        // User is admin, proceed to fetch data.
        fetchUsers();
        fetchGyms();
      } else {
        // User is not admin, redirect or hide admin content.
        navigate('/unauthorized');
      }
    });
  
    const fetchUsers = async () => {
      try {
        const usersCollectionRef = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollectionRef);
        const usersData = usersSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setUsers(usersData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
  
    const fetchGyms = async () => {
      try {
        const gymsCollectionRef = collection(db, 'gyms');
        const gymsSnapshot = await getDocs(gymsCollectionRef);
        const gymsData = gymsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setGyms(gymsData);
      } catch (error) {
        console.error("Error fetching gyms:", error);
      }
    };
  
    // Cleanup the subscription on unmount
    return () => unsubscribe();
  }, [auth, navigate]);

  const deleteUser = async (userId) => {
    try {
      const userDocRef = doc(db, 'users', userId);
      await deleteDoc(userDocRef);
      console.log("User deleted with ID:", userId);
      setUsers(users.filter(user => user.id !== userId));
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleSelectUser = (userId) => {
    const user = users.find(user => user.id === userId);
    console.log("Selected user:", user);
    setSelectedUser({ ...user, weight: user.weight || null, isActive: user.isActive !== undefined ? user.isActive : true });
  };

  const updateUser = async (userId, updatedFields) => {
    try {
      const userDocRef = doc(db, 'users', userId);
      await updateDoc(userDocRef, updatedFields);
      console.log("User updated with ID:", userId, "Updated fields:", updatedFields);
      setUsers(users.map(user => user.id === userId ? { ...user, ...updatedFields } : user));
      setSelectedUser(null);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleSelectGym = (gymId) => {
    const gym = gyms.find(gym => gym.id === gymId);
    console.log("Selected gym:", gym);
    setSelectedGym({ ...gym, latitude: gym.latitude || null, longitude: gym.longitude || null });
  };

  const updateGym = async (gymId, updatedFields) => {
    try {
      const gymDocRef = doc(db, 'gyms', gymId);
      const updatedData = {
        ...updatedFields,
        latitude: parseFloat(updatedFields.latitude),
        longitude: parseFloat(updatedFields.longitude),
      };
      await updateDoc(gymDocRef, updatedData);
      console.log("Gym updated with ID:", gymId, "Updated fields:", updatedData);
      setGyms(gyms.map(gym => gym.id === gymId ? { ...gym, ...updatedData } : gym));
      setSelectedGym(null);
    } catch (error) {
      console.error("Error updating gym:", error);
    }
  };

  return (
    <div className="admin-panel">
      <h2>Admin Panel</h2>
      <div className="user-list">
        {users.map(user => (
          <div key={user.id} className="user-item">
            <p>{user.firstName} {user.lastName}</p>
            <div>
              <button className="delete" onClick={() => deleteUser(user.id)}>Delete User</button>
              <button className="edit" onClick={() => handleSelectUser(user.id)}>Edit User</button>
            </div>
          </div>
        ))}
      </div>
  
      {selectedUser && (
        <div className="edit-form">
          <label>
            First Name:
            <input
              type="text"
              value={selectedUser.firstName}
              onChange={(e) => setSelectedUser({ ...selectedUser, firstName: e.target.value })}
            />
          </label>
          <label>
            Last Name:
            <input
              type="text"
              value={selectedUser.lastName}
              onChange={(e) => setSelectedUser({ ...selectedUser, lastName: e.target.value })}
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              value={selectedUser.email}
              onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
            />
          </label>
          <label>
            Username:
            <input
              type="text"
              value={selectedUser.username}
              onChange={(e) => setSelectedUser({ ...selectedUser, username: e.target.value })}
            />
          </label>
          <label>
            Region:
            <input
              type="text"
              value={selectedUser.region}
              onChange={(e) => setSelectedUser({ ...selectedUser, region: e.target.value })}
            />
          </label>
          <label>
            Weight:
            <input
              type="number"
              value={selectedUser.weight !== null ? selectedUser.weight : ''}
              onChange={(e) => setSelectedUser({ ...selectedUser, weight: e.target.value ? parseInt(e.target.value) : null })}
              min="0"
            />
          </label>
          <label>
            Years of Experience:
            <input
              type="number"
              value={selectedUser.yearsOfExperience}
              onChange={(e) => setSelectedUser({ ...selectedUser, yearsOfExperience: parseInt(e.target.value) })}
            />
          </label>
          <label>
            Is Active:
            <input
              type="checkbox"
              checked={selectedUser.isActive}
              onChange={(e) => setSelectedUser({ ...selectedUser, isActive: e.target.checked })}
            />
          </label>
          <button onClick={() => updateUser(selectedUser.id, selectedUser)}>Save Changes</button>
        </div>
      )}

      <h3>Gyms</h3>
      <div className="gym-list">
        {gyms.map(gym => (
          <div key={gym.id} className="gym-item">
            <p>{gym.name}</p>
            <button onClick={() => handleSelectGym(gym.id)}>Edit Gym</button>
          </div>
        ))}
      </div>

      {selectedGym && (
        <div className="edit-form">
          <label>
            Name:
            <input
              type="text"
              value={selectedGym.name}
              onChange={(e) => setSelectedGym({ ...selectedGym, name: e.target.value })}
            />
          </label>
          <label>
            Latitude:
            <input
              type="number"
              step="any"
              value={selectedGym.latitude !== null ? selectedGym.latitude : ''}
              onChange={(e) => setSelectedGym({ ...selectedGym, latitude: e.target.value })}
            />
          </label>
          <label>
            Longitude:
            <input
              type="number"
              step="any"
              value={selectedGym.longitude !== null ? selectedGym.longitude : ''}
              onChange={(e) => setSelectedGym({ ...selectedGym, longitude: e.target.value })}
            />
          </label>
          <button onClick={() => updateGym(selectedGym.id, selectedGym)}>Save Changes</button>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;