import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  VStack,
  useMediaQuery,
  Text,
} from '@chakra-ui/react';
import { Bar } from 'react-chartjs-2';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase-config';
import 'chart.js/auto';

const Data = () => {
  const [matches, setMatches] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedQuery, setSelectedQuery] = useState('');
  const [chartData, setChartData] = useState({});
  const [chartTitle, setChartTitle] = useState('');
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDocs = await getDocs(collection(db, 'users'));
        const matchDocs = await getDocs(collection(db, 'matches'));

        const users = userDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const matches = matchDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        setUsers(users);
        setMatches(matches);

        console.log('Fetched Users:', users);
        console.log('Fetched Matches:', matches);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const runQuery = useCallback(() => {
    let result = [];
    const beltRanks = ['White', 'Blue', 'Purple', 'Brown', 'Black'];

    if (selectedQuery === 'averageEloByBeltRank') {
      result = beltRanks.map(beltRank => {
        const filteredUsers = users.filter(user => user.beltRank === beltRank);
        const averageElo = filteredUsers.reduce((sum, user) => sum + user.elo, 0) / filteredUsers.length || 0;
        return { beltRank, averageElo };
      });
    }

    if (selectedQuery === 'submissionCountByType') {
      const submissionTypes = [...new Set(matches.map(match => match.submission))];
      result = submissionTypes.map(type => {
        const count = matches.filter(match => match.submission === type).length;
        return { submissionType: type, count };
      });
    }

    if (selectedQuery === 'averageWeightByBeltRank') {
      result = beltRanks.map(beltRank => {
        const filteredUsers = users.filter(user => user.beltRank === beltRank);
        const averageWeight = filteredUsers.reduce((sum, user) => sum + user.weight, 0) / filteredUsers.length || 0;
        return { beltRank, averageWeight };
      });
    }

    if (selectedQuery === 'averageWeightBySubmission') {
      const submissionTypes = [...new Set(matches.map(match => match.submission))];
      result = submissionTypes.map(type => {
        const filteredMatches = matches.filter(match => match.submission === type);
        const averageWeight = filteredMatches.reduce((sum, match) => sum + match.weightOne + match.weightTwo, 0) / (2 * filteredMatches.length) || 0;
        return { submissionType: type, averageWeight };
      });
    }

    if (selectedQuery === 'averageEloBySubmission') {
      const submissionTypes = {};
      matches.forEach((match) => {
        if (match.submission) {
          const winner = users.find((user) => user.id === match.winnerId);
          if (winner) {
            if (!submissionTypes[match.submission]) {
              submissionTypes[match.submission] = { totalElo: 0, count: 0 };
            }
            submissionTypes[match.submission].totalElo += winner.elo || 0;
            submissionTypes[match.submission].count += 1;
          }
        }
      });

      result = Object.keys(submissionTypes).map(submission => ({
        submissionType: submission,
        averageElo: submissionTypes[submission].totalElo / submissionTypes[submission].count || 0,
      }));
    }

    if (selectedQuery === 'averageDurationBySubmission') {
      const submissionTypes = [...new Set(matches.map(match => match.submission))];
      result = submissionTypes.map(type => {
        const filteredMatches = matches.filter(match => match.submission === type);
        const averageDuration = filteredMatches.reduce((sum, match) => sum + match.duration, 0) / filteredMatches.length || 0;
        return { submissionType: type, averageDuration };
      });
    }

    setChartData({
      labels: result.map(res => res.submissionType || res.beltRank),
      datasets: [{
        label: selectedQuery.includes('Weight') ? 'Average Weight' : selectedQuery.includes('Elo') ? 'Average Elo' : selectedQuery.includes('Duration') ? 'Average Duration (seconds)' : 'Submission Count',
        data: result.map(res => res.averageWeight || res.averageElo || res.count || res.averageDuration),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      }]
    });

    setChartTitle(selectedQuery.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()));
    console.log('Query Result:', result);
    console.log('Final Chart Data:', chartData);
  }, [selectedQuery, users, matches]);

  return (
    <Box w="100%" p={4}>
      <VStack spacing={4}>
        <FormControl>
          <FormLabel>Choose a Query</FormLabel>
          <Select onChange={(e) => setSelectedQuery(e.target.value)} placeholder="Select a query">
            <option value="averageEloByBeltRank">Average Elo by Belt Rank</option>
            <option value="submissionCountByType">Submission Count by Type</option>
            <option value="averageWeightByBeltRank">Average Weight by Belt Rank</option>
            <option value="averageWeightBySubmission">Average Weight by Submission</option>
            <option value="averageEloBySubmission">Average Elo by Submission</option>
            <option value="averageDurationBySubmission">Average Duration by Submission</option>
          </Select>
        </FormControl>
        <Button onClick={runQuery}>Results</Button>
        {chartData.labels && (
          <Box w="100%" h={isLargerThan600 ? "400px" : "300px"}>
            <Bar
              data={chartData}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y: {
                    min: selectedQuery.includes('Weight') ? 120 : selectedQuery.includes('Elo') ? 1000 : 0,
                    max: selectedQuery.includes('Weight') ? 200 : selectedQuery.includes('Elo') ? 2000 : undefined,
                  },
                },
              }}
            />
            <Box mt={4}>
              <Text textAlign="center" fontSize="lg">
                {chartTitle}
              </Text>
            </Box>
          </Box>
        )}
      </VStack>
    </Box>
  );
};

export default Data;
