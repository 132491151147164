import React, { useState, useEffect } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { db } from '../firebase-config';
import { collection, addDoc, getDocs, doc, updateDoc } from 'firebase/firestore';

const LoadGoogleMap = ({ children }) => {
  useEffect(() => {
    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA1cukicwr5V1bgq7K2FQ5WDEck87fh0&libraries=places`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);
  return children;
};

const Gyms = () => {
  const [gymName, setGymName] = useState('');
  const [address, setAddress] = useState('');
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [users, setUsers] = useState([]);
  const [gyms, setGyms] = useState([]);
  const [selectedGym, setSelectedGym] = useState('');
  const [selectedCaptain, setSelectedCaptain] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, 'users'));
        const usersData = usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setUsers(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    const fetchGyms = async () => {
      try {
        const gymsSnapshot = await getDocs(collection(db, 'gyms'));
        const gymsData = gymsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setGyms(gymsData);
      } catch (error) {
        console.error('Error fetching gyms:', error);
      }
    };

    fetchUsers();
    fetchGyms();
  }, []);

  const handleSelect = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      setAddress(value);
      setLocation(latLng);
      console.log(latLng);
    } catch (error) {
      console.error('Error geocoding address:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitted(false);
    if (!gymName || !address || !location.lat || !location.lng) {
      alert("Please provide both gym name and address, and make sure the address is selected from the dropdown.");
      return;
    }
    try {
      const docRef = await addDoc(collection(db, "gyms"), {
        name: gymName,
        address: address,
        latitude: location.lat,
        longitude: location.lng
      });
      console.log("Document written with ID: ", docRef.id);
      setGymName('');
      setAddress('');
      setLocation({ lat: null, lng: null });
      setIsSubmitted(true);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleAssignCaptain = async () => {
    if (!selectedGym || !selectedCaptain) {
      alert('Please select a gym and a captain.');
      return;
    }
    try {
      const gymRef = doc(db, 'gyms', selectedGym);
      await updateDoc(gymRef, { captain: selectedCaptain });
      alert('Captain assigned successfully!');
      setSelectedGym('');
      setSelectedCaptain('');
    } catch (error) {
      console.error('Error assigning captain:', error);
    }
  };

  return (
    <div className="container">
      <h1>Add New Gym</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="gymName">Gym Name:</label>
          <input
            type="text"
            id="gymName"
            value={gymName}
            onChange={(e) => setGymName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="address">Address:</label>
          <LoadGoogleMap>
            <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <input {...getInputProps({ placeholder: 'Type address' })} />
                  <div>
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const style = suggestion.active
                        ? { backgroundColor: '#a8d0e6', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, { style })}
                          key={suggestion.placeId}
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </LoadGoogleMap>
        </div>
        <button type="submit">Add Gym</button>
      </form>
      {isSubmitted && <div className="success-message">Gym Added Successfully!</div>}

      <h1>Assign Captain to Gym</h1>
      <div>
        <label htmlFor="gym">Select Gym:</label>
        <select
          id="gym"
          value={selectedGym}
          onChange={(e) => setSelectedGym(e.target.value)}
          required
        >
          <option value="">Select a gym</option>
          {gyms.map((gym) => (
            <option key={gym.id} value={gym.id}>
              {gym.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="captain">Select Captain:</label>
        <select
          id="captain"
          value={selectedCaptain}
          onChange={(e) => setSelectedCaptain(e.target.value)}
          required
        >
          <option value="">Select a captain</option>
          {users.map((user) => (
            <option key={user.id} value={user.id}>
              {user.firstName} {user.lastName} ({user.username})
            </option>
          ))}
        </select>
      </div>
      <button onClick={handleAssignCaptain}>Assign Captain</button>
    </div>
  );
};

export default Gyms;