import React, { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs, writeBatch, doc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Import Firebase Auth
import './UpcomingMatches.css';


const UpcomingMatches = () => {
  const [gymsWithSlots, setGymsWithSlots] = useState([]);
  const [currentUser, setCurrentUser] = useState(null); // State to track the current user
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    // Listen for auth state changes
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setCurrentUser(user);
      } else {
        // User is signed out
        setCurrentUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup on component unmount
  }, []);

  useEffect(() => {
    const fetchTimeSlots = async () => {
      setLoading(true);
      setError('');

      try {
        const gymCollectionRef = collection(db, 'gyms');
        const gymSnapshot = await getDocs(gymCollectionRef);

        if (gymSnapshot.empty) {
          throw new Error('No gyms found.');
        }

        const gyms = gymSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const gymsDataPromises = gyms.map(async (gym) => {
          const slotsCollectionRef = collection(db, 'gyms', gym.id, 'availableTimeSlots');
          const slotsSnapshot = await getDocs(slotsCollectionRef);

          let slots = [];
          if (!slotsSnapshot.empty) {
            slots = slotsSnapshot.docs.map(slotDoc => ({
              id: slotDoc.id,
              ...slotDoc.data()
            }));
          }

          return { ...gym, availableTimeSlots: slots };
        });

        const gymsData = await Promise.all(gymsDataPromises);
        setGymsWithSlots(gymsData.filter(gym => gym.availableTimeSlots.length > 0));
      } catch (e) {
        setError(e.message);
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    fetchTimeSlots();
  }, []);

  const handleReserveSlot = async (gymId, slotId, slotIndex, slotTimeIndex) => {
    if (!currentUser) {
      setError('You must be logged in to reserve a slot.');
      return; // Exit if there is no logged-in user
    }

    setLoading(true);
    try {
      // Begin the batch write
      const batch = writeBatch(db);

      const slotRef = doc(db, 'gyms', gymId, 'availableTimeSlots', slotId);
      const userRef = doc(db, 'gyms', gymId, 'userReservations', currentUser.uid);

      // Retrieve the current slot
      const currentSlot = gymsWithSlots.find(gym => gym.id === gymId).availableTimeSlots.find(slot => slot.id === slotId);

      // Update the available time slot
      const updatedSlotTimes = currentSlot.slots.map((time, index) => {
        if (index === slotTimeIndex) {
          const updatedTime = { ...time, number: time.number - 1 };
          return updatedTime;
        }
        return time;
      });

      const updatedSlot = {
        ...currentSlot,
        slots: updatedSlotTimes.filter(time => time.number > 0)
      };

      // Update the available time slot in Firestore
      batch.update(slotRef, updatedSlot);

      // Add the user reservation to the batch
      const reservedTime = currentSlot.slots[slotTimeIndex];
      batch.set(userRef, {
        slotId: slotId,
        userId: currentUser.uid,
        time: reservedTime.time
      });

      // Commit the batch write to Firestore
      await batch.commit();

      // Update the local state with the new slots
      const updatedGymsWithSlots = gymsWithSlots.map(gym => {
        if (gym.id === gymId) {
          const updatedSlots = gym.availableTimeSlots.map(slot => {
            if (slot.id === slotId) {
              return updatedSlot;
            }
            return slot;
          });
          return { ...gym, availableTimeSlots: updatedSlots };
        }
        return gym;
      });

      setGymsWithSlots(updatedGymsWithSlots);
      alert('Slot reserved successfully!');
    } catch (error) {
      setError('Failed to reserve the slot. Please try again.');
      console.error('Error reserving slot:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (gymsWithSlots.length === 0) {
    return <div>No upcoming matches found.</div>;
  }

  return (
    <div className="upcoming-matches">
      <h2>Upcoming Matches</h2>
      {gymsWithSlots.map(gym => (
        <div key={gym.id} className="gym">
          <h3>{gym.name}</h3>
          {gym.availableTimeSlots.map((slot, slotIndex) => (
            <div key={slot.id} className="slot">
              <p>{slot.date}</p>
              {slot.slots.map((time, index) => (
                <div key={index} className="time">
                  <span>{time.time} - Slots: {time.number}</span>
                  {time.number > 0 && (
                    <button
                      className="reserve-button"
                      onClick={() => handleReserveSlot(gym.id, slot.id, slotIndex, index)}
                    >
                      Reserve Slot
                    </button>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default UpcomingMatches;