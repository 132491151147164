// PrivacyPolicy.js
import React from 'react';
import './PrivacyPolicy.css'; // Ensure you have this CSS file in the same directory

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy for Precision Grappling Rankings</h1>
      <p className="effective-date">Effective Date: [4/7/2024]</p>

      <section>
        <p>Welcome to Precision Grappling Rankings. We respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and share information from or about you when you use our services.</p>
      </section>

      <section>
        <h2>Information We Collect</h2>
        <p>Information you provide to us:</p>
        <ul>
          <li>Account registration information, such as your email address, username, and password.</li>
          <li>Profile information that you provide for your user profile (e.g., first and last name, picture).</li>
          <li>Content that you create and share on our platform (e.g., photos, videos, comments, and other materials).</li>
        </ul>
        <p>Information we collect automatically when you use our services:</p>
        <ul>
          <li>Usage information about how you interact with our website.</li>
          <li>Log information, including your IP address, browser type, access times, pages viewed, and the page you visited before navigating to our services.</li>
          <li>Device information, such as hardware model, operating system version, unique device identifiers, and mobile network information.</li>
        </ul>
      </section>

      <section>
        <h2>How We Use Your Information</h2>
        <p>We use the information we collect to:</p>
        <ul>
          <li>Provide, maintain, and improve our services.</li>
          <li>Develop new services and features that meet your needs.</li>
          <li>Personalize the services and offer tailored content such as more relevant search results.</li>
          <li>Communicate with you about services, offers, promotions, rewards, and events offered by Precision Grappling Rankings.</li>
          <li>Monitor and analyze trends, usage, and activities in connection with our services.</li>
          <li>Detect, investigate and prevent fraudulent transactions and other illegal activities and protect the rights and property of Precision Grappling Rankings and others.</li>
        </ul>
      </section>

      <section>
        <h2>Sharing Of Information</h2>
        <p>We may share information about you as follows:</p>
        <ul>
          <li>With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf.</li>
          <li>In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation, or legal process.</li>
          <li>Between and among Precision Grappling Rankings and our current and future parents, affiliates, subsidiaries, and other companies under common control and ownership.</li>
          <li>With your consent or at your direction.</li>
          <li>We may also share aggregated or de-identified information, which cannot reasonably be used to identify you.</li>
        </ul>
      </section>

      <section>
        <h2>Your Choices</h2>
        <p>Account Information: You may update, correct or delete information about you at any time by logging into your online account or emailing us at <a href="mailto:support@precisiongrapplingrankings.com">support@precisiongrapplingrankings.com</a>.</p>
        <p>Cookies: Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject cookies.</p>
      </section>

      <section>
        <h2>Data Retention</h2>
        <p>We store the information we collect about you for as long as is necessary for the purpose(s) for which we originally collected it, or for other legitimate business purposes, including to meet our legal, regulatory, or other compliance obligations.</p>
      </section>

      <section>
        <h2>Data Security</h2>
        <p>We use certain physical, managerial, and technical safeguards that are designed to improve the integrity and security of information that we collect and maintain.</p>
      </section>

      <section>
        <h2>Changes to This Privacy Policy</h2>
        <p>We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice (such as adding a statement to our homepage or sending you a notification).</p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@precisiongrapplingrankings.com">support@precisiongrapplingrankings.com</a>.</p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
