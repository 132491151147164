import React, { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs, getDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { Box, VStack, HStack, Select, Button, Heading, FormLabel, Text } from '@chakra-ui/react';

const MatchManager = () => {
  const [matches, setMatches] = useState([]);
  const [users, setUsers] = useState([]);
  const [gyms, setGyms] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchResources = async () => {
      setLoading(true);
      try {
        const [usersSnapshot, gymsSnapshot, matchesSnapshot] = await Promise.all([
          getDocs(collection(db, 'users')),
          getDocs(collection(db, 'gyms')),
          getDocs(collection(db, 'matches')),
        ]);

        const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const gymsData = gymsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const matchesData = await Promise.all(matchesSnapshot.docs.map(async (matchDoc) => {
          const matchData = matchDoc.data();
          const user1Doc = await getDoc(doc(db, 'users', matchData.userId1));
          const user2Doc = await getDoc(doc(db, 'users', matchData.userId2));
          const gymDoc = await getDoc(doc(db, 'gyms', matchData.gymId));

          return {
            id: matchDoc.id,
            user1: user1Doc.data(),
            user2: user2Doc.data(),
            gym: gymDoc.data(),
            ...matchData,
          };
        }));

        setUsers(usersData);
        setGyms(gymsData);
        setMatches(matchesData);
      } catch (error) {
        console.error('Error fetching resources:', error);
      }
      setLoading(false);
    };

    fetchResources();
  }, []);

  const handleUserChange = (matchId, userId, userType) => {
    setMatches(prevMatches => {
      return prevMatches.map(match => {
        if (match.id === matchId) {
          return {
            ...match,
            [`userId${userType}`]: userId,
          };
        }
        return match;
      });
    });
  };

  const handleSaveChanges = async (matchId) => {
    const match = matches.find(match => match.id === matchId);
    if (match) {
      const { userId1, userId2 } = match;
      const matchRef = doc(db, 'matches', matchId);
      await updateDoc(matchRef, { userId1, userId2 });
    }
  };

  const handleDeleteMatch = async (matchId) => {
    await deleteDoc(doc(db, 'matches', matchId));
    setMatches(prevMatches => prevMatches.filter(match => match.id !== matchId));
  };

  return (
    <Box p={5}>
      <Heading as="h2" mb={5}>Manage Matches</Heading>
      {loading ? (
        <Text>Loading...</Text>
      ) : (
        <VStack spacing={8}>
          {matches.map(match => (
            <Box key={match.id} p={5} shadow="md" borderWidth="1px" borderRadius="lg">
              <VStack spacing={4} align="stretch">
                <Text>Gym: {match.gym?.name || 'Unknown'}</Text>
                <HStack justify="space-between">
                  <FormLabel>Participant 1:</FormLabel>
                  <Select
                    value={match.userId1}
                    onChange={(e) => handleUserChange(match.id, e.target.value, '1')}
                  >
                    {users.map(user => (
                      <option key={user.id} value={user.id}>
                        {user.firstName} {user.lastName}
                      </option>
                    ))}
                  </Select>
                </HStack>
                <HStack justify="space-between">
                  <FormLabel>Participant 2:</FormLabel>
                  <Select
                    value={match.userId2}
                    onChange={(e) => handleUserChange(match.id, e.target.value, '2')}
                  >
                    {users.map(user => (
                      <option key={user.id} value={user.id}>
                        {user.firstName} {user.lastName}
                      </option>
                    ))}
                  </Select>
                </HStack>
                <HStack justify="flex-end">
                  <Button onClick={() => handleSaveChanges(match.id)}>Save Changes</Button>
                  <Button colorScheme="red" onClick={() => handleDeleteMatch(match.id)}>Delete Match</Button>
                </HStack>
              </VStack>
            </Box>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default MatchManager;