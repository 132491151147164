import './firebase-config';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, browserLocalPersistence, setPersistence} from 'firebase/auth';
import Login from './components/Login';
import RegistrationForm from './components/RegistrationForm';
import Dashboard from './components/Dashboard'; 
import UserProfile from './components/UserProfile';
import UpcomingMatches from './components/UpcomingMatches';
import MatchHistory from './components/MatchHistory';
import AdminPanel from './components/AdminPanel';
import FindMatch from './components/FindMatch';
import RecordMatches from './components/RecordMatches';
import AdminMatches from './components/AdminMatches';
import Waiver from './components/Waiver';
import ChakraWrapper from './components/ChakraWrapper';
import TimeSlotDelete from './components/TimeSlotDelete';
import Data from './components/Data';

import Matches from './components/Matches';


import EmailForm from './components/EmailForm';

import UserVerificationStatus from './components/UserVerificationStatus';
import EloExplanation from './components/EloExplanation';
import Captain from './components/Captain';

import AboutUs from './components/AboutUs';


import UserLookup from './components/UserLookup';
import RefereeComponent from './components/RefereeComponent';
import PrivacyPolicy from './components/PrivacyPolicy';
import Support from './components/Support';

import List from './components/List';

import BecomeRef from './components/BecomeRef';
import Faq from './components/Faq';
import Rules from './components/Rules';
import Team from './components/Team';
import GymsMap from './components/GymsMap';
import SignUpForSlot from './components/SignUpForSlot';
import Rankings from './components/Rankings';
import Gyms from './components/Gyms';
import RefDashboard from './components/RefDashboard';
import AdminDashboard from './components/AdminDashboard';
import AddTimeslots from './components/AddTimeslots';
import MatchManager from './components/MatchManager';

import UpcomingEvents from './components/UpcomingEvents';



function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    // Set the Firebase Auth state persistence to session
    setPersistence(auth, browserLocalPersistence)
    .then(() => {
      return onAuthStateChanged(auth, (authUser) => {
        setUser(authUser);
        setLoading(false);
      });
    })
    .catch((error) => {
      console.error("Error setting auth persistence:", error);
    });
    }, [auth]);

  // Define a ProtectedRoute component
  const ProtectedRoute = ({ children }) => {
    if (loading) {
      return <div>Loading...</div>;
    }
    return user ? children : <Navigate to="/" />;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
  
    <Router>
      <Routes>
    
        {/* Wrap only the Login route with ChakraWrapper */}
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/UserVerificationStatus" element={<ChakraWrapper><UserVerificationStatus /></ChakraWrapper>} />
        <Route path="/login" element={<ChakraWrapper><Login /></ChakraWrapper>} />
        <Route path="/Captain" element={<ChakraWrapper><Captain /></ChakraWrapper>} />
        <Route path="/UserLookup" element={<ChakraWrapper><UserLookup /></ChakraWrapper>} />
        <Route path="/registrationform" element={<ChakraWrapper><RegistrationForm /></ChakraWrapper>} />
        <Route path="/dashboard" element={<ChakraWrapper><Dashboard /></ChakraWrapper>} />
        <Route path="/userprofile" element={<ChakraWrapper><UserProfile /></ChakraWrapper>} />
        <Route path="/upcoming-matches" element={<ProtectedRoute><UpcomingMatches /></ProtectedRoute>} />
        <Route path="/matchhistory" element={<ChakraWrapper><MatchHistory /></ChakraWrapper>} />
        <Route path="/find-match" element={<ProtectedRoute><FindMatch /></ProtectedRoute>} />
        <Route path="/recordmatches" element={<ProtectedRoute><RecordMatches /></ProtectedRoute>} />
        <Route path="/rankings" element={<ChakraWrapper><Rankings /></ChakraWrapper>} />
        <Route path="/List" element={<ChakraWrapper><List /></ChakraWrapper>} />

        <Route path="/AboutUs" element={<ChakraWrapper><AboutUs /></ChakraWrapper>} />

        <Route path="/Matches" element={<ChakraWrapper><Matches /></ChakraWrapper>} />


        <Route path="/Data" element={<ProtectedRoute><Data /></ProtectedRoute>} />

        <Route path="/gyms" element={<ProtectedRoute><Gyms /></ProtectedRoute>} />
        <Route path="/GymsMap" element={<ChakraWrapper><GymsMap /></ChakraWrapper>} />
        <Route path="/RefDashboard" element={<ChakraWrapper><RefDashboard /></ChakraWrapper>} />
        <Route path="/AdminDashboard" element={<ChakraWrapper><AdminDashboard /></ChakraWrapper>} />
        <Route path="/Waiver" element={<ChakraWrapper><Waiver /></ChakraWrapper>} />
        <Route path="/EloExplanation" element={<ChakraWrapper><EloExplanation /></ChakraWrapper>} />
        <Route path="/TimeSlotDelete" element={<ProtectedRoute><TimeSlotDelete /></ProtectedRoute>} />
        <Route path="/Team" element={<ChakraWrapper><Team /></ChakraWrapper>} />
        <Route path="/PrivacyPolicy" element={<ProtectedRoute><PrivacyPolicy /></ProtectedRoute>} />
        <Route path="/Support" element={<ProtectedRoute><Support /></ProtectedRoute>} />

        <Route path="/BecomeRef" element={<ProtectedRoute><BecomeRef /></ProtectedRoute>} />
        <Route path="/Rules" element={<ProtectedRoute><Rules /></ProtectedRoute>} />
        <Route path="/Faq" element={<ProtectedRoute><Faq /></ProtectedRoute>} />
        <Route path="/EmailForm" element={<ChakraWrapper><EmailForm /></ChakraWrapper>} />
        <Route path="/UpcomingEvents" element={<ProtectedRoute><ChakraWrapper><UpcomingEvents /></ChakraWrapper></ProtectedRoute>} />

        <Route path="/RefereeComponent" element={<ProtectedRoute><ChakraWrapper><RefereeComponent /></ChakraWrapper></ProtectedRoute>} />
        <Route path="/adminpanel" element={<ProtectedRoute><AdminPanel /></ProtectedRoute>} />
        <Route path="/adminmatches" element={<ProtectedRoute><AdminMatches /></ProtectedRoute>} />
        <Route path="/signupforslot" element={<ProtectedRoute><SignUpForSlot /></ProtectedRoute>} />
        <Route path="/addtimeslots" element={<ProtectedRoute><AddTimeslots /></ProtectedRoute>} />
        <Route path="/MatchManager" element={<ProtectedRoute><ChakraWrapper><MatchManager /></ChakraWrapper></ProtectedRoute>} />
        <Route path="*" element={<div>Route not found - fallback route</div>} />
      </Routes>
    </Router>
  
  );
}

export default App;