import React from 'react';
import './Rules.css'; // Ensure you create a corresponding CSS file
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Rules = () => {
  // Array of rules for display
  const rules = [
    "Matches will be 10 mins long.",
    "All dominant postional changes will be worth 1 point.",
    "Taking the Back, 1 point.",
    "Mount, 1 point.",
    "Side control, 1 point.",
    "A succesful takedown, with control will be, 1 point.",
    "A pass will be awarded 1 point.",
    "A sweep will be awarded 1 point",
    "A takedown into a dominant poistion will be awarded 1 point + 1 point for the position.",
    "You will gain a point for any change to a dominant poistion, this includes passes and changing to a different dominant position.",
    "Matches will end if a submission is achieved.",
    "No slams, scissor take downs, suplexes.",

    // Add more rules here
  ];
  const navigate = useNavigate(); // Initialize useNavigate
  return (
    <div className="rules-container">
      <h2>Competition Rules</h2>
      <ul>
        {rules.map((rule, index) => (
          <li key={index} className="rule">{rule}</li>
        ))}
      </ul>
      <button className="back-dashboard" onClick={() => navigate('/dashboard')}>Back to Dashboard</button>
    </div>
  );
};

export default Rules;
